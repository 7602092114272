/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import dashboardStyles from './DashboardStyling'
import SearchBar from './display/searchBar/searchbar'

class DashboardHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const { date } = this.props
    return (
      <div className="row">
        <div className="col-lg-8 col-12" ><div>

          <div className='container'>
            <div className="row">
              <div className="col-12">
                <h1 className="m-0" style={dashboardStyles.greeting_sizing}>
                  {`Hello, Dyper`}
                </h1>
                <div style={dashboardStyles.date_styling}>{date}</div>
                <br />
              </div>
            </div>
          </div>
        </div>
        </div>

        <div className="col-lg-4 col-7">
          <div>

            <div className='container'>
              <div className="row">
                <div className="col-12 " >
                  {/* <SearchBar /> */}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getLocations: state.getLocations,
  };
};

export default connect(mapStateToProps)(DashboardHeader);
