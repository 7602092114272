/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './App'
import LoginScreen from './loginScreen'
// redux
import { Provider } from "react-redux";

import store from './components/redux/store/store'

import { Auth } from 'aws-amplify';

import awsconfig from './aws-exports'

// Amplify.configure(awsconfig_dev)
// >>New - Configuring Auth Module.

Auth.configure(awsconfig);

ReactDOM.render(
  <Provider store={store}>
    <LoginScreen />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
