/* eslint-disable */
import React, { Component } from "react";
import tableStyling from '../table_styling/tableStyling'
import months from '../helpers/api_services/months'
import moment from 'moment'

const columnSizing = {
    nameColumn: {
        fontWeight: 'bold', width: '15%', textDecoration: 'underline'
    },
    timeColumn: {
        width: '22%'
    },
    issueColumn: {
        width: '9%'
    },
    pictureColum: {
        width: '5%'
    },
    pictureIconColor: {
        width: '1%',
        color: '#FCC816'
    },
    noteColumn: {
        width: '1%',
        whiteSpace: 'normal',
        wordWrap: 'break-word'
    },
}

class PickupsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            todaysDate: '',
            tomorrowsDate: '',

            isToday: '',
            isTomorrow: '',
        };
    }
    async componentDidMount() {
        let datetime = new Date().toISOString()
        let year = datetime.substring(0, 10).split('-')[0]
        let month = datetime.substring(0, 10).split('-')[1]
        let day = datetime.substring(0, 10).split('-')[2]

        const today = new Date()
        const tomorrow = new Date(datetime)
        const tomorrow1 = new Date(tomorrow.setDate(tomorrow.getDate() + 1)).toISOString()

        // let datetimetest = new Date().toISOString()
        let year1 = tomorrow1.substring(0, 10).split('-')[0]
        let month2 = tomorrow1.substring(0, 10).split('-')[1]
        let day3 = tomorrow1.substring(0, 10).split('-')[2]

        let isToday = new Date(`${month},${day},${year}`)
        let isTomorrow = new Date(`${month2},${day3},${year1}`)

        this.setState({
            isToday: isToday,
            isTomorrow: isTomorrow,

            todaysDate: `${year}-${month}-${day}`,
            tomorrowsDate: `${month2}-${day3}-${year1}`
        })

    }

    render() {
        const { projectsArrLength, isPaginating, lastElementRef, searchTerm, loadMoreItems, projectsArr, fetchSingleCustomer, fetchSingleProject, prevOrCurrentToggle, indexOfSelectedPickup, setCurrentHandle, setPrevHandle, setAllHandle, rowClickedHandle, selectedPickupBool } = this.props
        const { todaysDate, tomorrowsDate, isToday, isTomorrow } = this.state

        let noDataShown = false

        if (projectsArrLength === 0) {
            noDataShown = true
        }
        return (
            <div>
                {projectsArrLength === 0
                    ? 'No Data'
                    : <div>
                        <div style={{ padding: '.2%' }}></div>

                        <table className={projectsArr.length > 0 ? "table table-striped table-hover btn text-left" : ''}
                            style={tableStyling.tableBodyPickup}
                        >
                            <thead>
                                {projectsArr.length > 0
                                    ? <tr >
                                        <th scope="col" style={tableStyling.tableHeaderStyle}>Customer name</th>
                                        <th scope="col" style={tableStyling.tableHeaderStyle}>Address</th>
                                        <th scope="col" style={tableStyling.tableHeaderStyle}>Check in</th>
                                        <th scope="col" style={tableStyling.tableHeaderStyle}>Issues</th>
                                        <th scope="col" style={tableStyling.tableHeaderStyle}>Photo</th>
                                        <th scope="col" style={tableStyling.tableHeaderStyle}>Notes</th>
                                    </tr>
                                    : <tr >
                                        <th scope="col"></th>
                                    </tr>
                                }
                            </thead>
                            <tbody>
                                {projectsArr.length > 0
                                    ? projectsArr.map((data, i) => {
                                        let datetime

                                        if (data.checkin_timestamp) {
                                            datetime = data.checkin_timestamp
                                        } else {
                                            datetime = data.issue_timestamp
                                        }

                                        let currentTimeZone = new Date(datetime).toString()

                                        let monthIntoNum = ''
                                        let convertMilitaryTime = ''
                                        let currentTimeZoneShortened = ''

                                        if (datetime) {
                                            for (let key in months) {
                                                if (key === currentTimeZone.split(' ')[1]) {
                                                    monthIntoNum = months[key]
                                                }
                                            }
                                            convertMilitaryTime = moment(currentTimeZone.split(' ')[4], 'HH:mm:ss').format('h:mm:ss A')
                                            currentTimeZoneShortened = `${monthIntoNum}-${currentTimeZone.split(' ')[2]}-${currentTimeZone.split(' ')[3]} ${convertMilitaryTime}`
                                        }

                                        let issuesReported = data.issues_reported
                                        let issuesReportedString = ''

                                        for (let i = 0; i < issuesReported.length; i++) {
                                            if (issuesReported[i].length > 0) {
                                                issuesReportedString += `${issuesReported[i]} `
                                            } else {
                                                issuesReportedString = '-'
                                            }
                                        }
                                        return (
                                            <tr key={i}
                                                onClick={() => rowClickedHandle()}
                                                style={indexOfSelectedPickup === i && selectedPickupBool ? { backgroundColor: '#FCC816' } : { backgroundColor: '' }}
                                            >
                                                {data.firstname && data.lastname
                                                    ? <td style={data.firstname === undefined || !data.lastname === undefined ? { color: 'black' } : columnSizing.nameColumn} onClick={() => fetchSingleCustomer(data.subscription_id)}>{data.firstname === undefined || !data.lastname === undefined ? '-' : `${data.firstname} ${data.lastname}`}</td>
                                                    : <td style={data.firstname === undefined || !data.lastname === undefined ? { color: 'black' } : columnSizing.nameColumn} onClick={() => fetchSingleProject(data, i)}>{data.firstname === undefined || !data.lastname === undefined ? '-' : `${data.firstname} ${data.lastname}`}</td>
                                                }
                                                <td onClick={() => fetchSingleProject(data, i)} style={columnSizing.timeColumn}>{data.full_address === undefined ? '-' : `${data.full_address}`}</td>
                                                <td onClick={() => fetchSingleProject(data, i)} style={columnSizing.timeColumn}>{`${currentTimeZoneShortened} (Local)`}</td>
                                                <td onClick={() => fetchSingleProject(data, i)} style={columnSizing.issueColumn}>{data.issues_reported.length > 0 ? `${issuesReportedString}` : '-'}</td>
                                                <td onClick={() => fetchSingleProject(data, i)} style={columnSizing.pictureColume}>
                                                    {data.photo_link
                                                        ? <div className="far fa-image center" style={columnSizing.pictureIconColor} ></div>
                                                        : <div >No Image</div>}

                                                </td>
                                                <td onClick={() => fetchSingleProject(data, i)} >{data.notes.length > 0 ? `${data.notes}` : '-'}</td>
                                            </tr>
                                        )

                                    })
                                    : <tr  >
                                        <td >{`No Data`}</td>
                                    </tr>
                                }
                            </tbody>
                        </table >
                        <div style={{ paddingLeft: '2%' }} ref={prevOrCurrentToggle === 'showCurrent' || searchTerm.length !== 0 ? '' : lastElementRef}>
                            {searchTerm.length === 0 && prevOrCurrentToggle !== 'showCurrent'
                                ? noDataShown ? '' : 'loading...'
                                : ''}
                        </div>
                    </div >
                }


            </div>
        );
    }
}

export default PickupsTable
