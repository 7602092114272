// import { locationApi } from '../../helpers/api_services/fetchLocations'

const initialState = {
  locations: []
}

const locationsApi = async (state = initialState, action) => {
  try {
    const newState = { ...state }
    // const locationsArr = await locationApi()

    // if (locationsArr) {
    //   let locationDataAlpha = locationsArr.sort((a, b) =>
    //     a.state > b.state ? 1 : b.state > a.state ? -1 : 0
    //   );
    //   newState.locations = [...locationDataAlpha]
    // }

    return newState
  } catch (e) {
    return null;
  }

};


export default locationsApi
