/* eslint-disable */
import React, { Component } from "react";
import { Auth } from 'aws-amplify';
import styles from '../../AuthStyling'

class NotAnEmployee extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div style={styles.welcomePageLayoutRight}>
                <div className="row text-center">
                    <div className="col-12" style={{ fontWeight: 'bold' }}>
                        <div >
                            {`Must be an employee of Recyclops to access.`}
                        </div>
                        <div>
                            {`If you are an employee, please request access from your team.`}
                        </div>
                        <br />
                        <div>
                            <button style={styles.button} onClick={() => Auth.signOut()}>Return to Login Page</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NotAnEmployee