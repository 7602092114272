import { Auth } from "aws-amplify";

const initialState = {
  userData: 'empty',
  jwtToken: ''
}

const getCurrentUser = (state = initialState, action) => {
  const newState = { ...state }
  Auth.currentAuthenticatedUser().then((data) => {
    if (data) {
      newState.userData = data.attributes
      newState.jwtToken = data.signInUserSession.accessToken.jwtToken
    } else {
      newState.userData = null
      newState.jwtToken = null
    }
  }).catch((err) => console.log(err))
  return newState
};

export default getCurrentUser;
