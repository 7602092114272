/* eslint-disable */
import React, { Component } from "react";
import { Auth } from "aws-amplify";

class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async signOut() {
    const user = await Auth.signOut();
  }

  render() {
    return (
      <div>
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          {/* <div onClick={this.props.menuCollapsedBool}>
            <CloseMenu />
          </div> */}
          <div className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-10">
                  <h1 className="m-0">Logout Page</h1>
                  <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
                    Launch demo modal
                  </button>

                  <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          ...
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                          <button type="button" class="btn btn-primary">Save changes</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <button onClick={() => this.signOut()}>
                    Click here to logout
                  </button> */}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default Logout
