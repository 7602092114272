export async function fetchPickupsHistory(token, action) {
    try {
        const result = await fetch(`${process.env.REACT_APP_FETCH_ALL_PICKUPS}?ps=100&ed=${action}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': `Bearer ${token}`,
            },
            'credentials': 'include'
        })

        const data = await result.json();

        return data;
    } catch (e) {
        return null;
    }
}

// ?q=paul`
// ?days=1`
// ?q=____` // road warrior
// ?q=paul 2022-01`
// ?ps=10`

// ?sd=2022-02-02&ed=2022-02-02
// ?ed=2022-02-01