const menuStyles = {
  disable_menu_expand_hover: {
    // pointerEvents: "none",
  },
  menu_item_sizing: {
    fontSize: '14px', fontWeight: 'bold', letterSpacing: '1px',
    font: 'Quicksand'
  },
  menu_icon_sizing: {
    fontSize: '1.4vh'
  },
  menu_item_layout_style: {
    color: "white",
    fontWeight: 'bold',
    // paddingLeft: "22%",
  },
  menu_item_logout_layout_style: {
    color: "white",
    background: 'none',
    fontWeight: 'bold',
    paddingLeft: "22%",
  },
  dividerLine: {
    borderTop: "2px solid white",
  },
  menuCollapsedArrolwColor: {
    color: "#2A9B8D",
  },
  bottomMenuPosition: {
    position: 'fixed',
    bottom: '0'
  },
  menu_department_button: {
    background: "rgba(255, 255, 255, 0.5)",
    borderRadius: "20px",
    padding: "5%",
    width: "80%",
    borderColor: "rgba(255, 255, 255, 0.5)",
  },
  menu_department_button_center: {
    color: "white",
    background: "rgba(255, 255, 255, 0.5)",
    borderRadius: "20px",
    padding: "8%",
    width: "80%",
    borderColor: "rgba(255, 255, 255, 0.5)",
    borderWidth: "1px 0 1px 0",
    borderStyle: "solid",
  },
  menu_item_layout: {
    color: "white",
    fontSize: "17px",
    paddingLeft: "50px",
  },
  menu_item_icon_layout: {
    paddingLeft: "10px",
    cursor: "pointer",
  },
  lower_menu_items: {
    // paddingTop: "100%",
  },
  profile_button_styling: {
    color: "white",
    background: "rgba(255, 255, 255, 0.5)",
    borderRadius: "20px",
    padding: "5%",
    width: "90%",
    borderColor: "rgba(255, 255, 255, 0.5)",
    borderWidth: "1px 0 1px 0",
    borderStyle: "solid",
  }
};

export default menuStyles;
