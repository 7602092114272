export async function fetchCustomers(token) {
    try {
        let result = await fetch(`${process.env.REACT_APP_FETCH_ALL_CUSTOMERS}?ps=100`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': `Bearer ${token}`,
            },
            'credentials': 'include'
        });

        const data = await result.json();

        return data;
    } catch (e) {
        return null;
    }
}

// &ps=5&?
// ?ps=10`