/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import ReduxTest from '../../reduxtest'
import { AmplifySignOut } from '@aws-amplify/ui-react'
import Amplify, { Auth, Hub, API, graphqlOperation } from 'aws-amplify';
import Profile from './profile'
import profileStyles from './profileStying'

class ProfileLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { name, team, editModeOn, errorMsg } = this.state;
    return (
      <div style={profileStyles.profile_padding_Left}>
        <div
          className="content-wrapper"
          style={profileStyles.profile_background_color}
        >

          <section className="content">
            <div className="container-fluid">

              <br />

              <div className="row">

                <section className="col-lg-7 connectedSortable">
                  <div className="card" style={profileStyles.profile_box_layout}>
                    <div className="card-body" style={profileStyles.centerEverything}>
                      <div style={profileStyles.profile_title_Font}>

                        <div >
                          <Profile />
                          {/* profile goes here*/}
                        </div>
                      </div>

                    </div>
                  </div>
                </section>

                <section className="col connectedSortable">

                  <div className="card " style={profileStyles.right_side_box_1}>
                    <div className="card-body" style={profileStyles.centerEverything}>Calendar<div />
                    </div>
                  </div>

                  <div className="card" style={profileStyles.right_side_box_2}>
                    <div className="card-body" style={profileStyles.centerEverything}>Messages<div />
                    </div>
                  </div>

                </section>

              </div>
            </div>

          </section>

        </div>
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    updatedData: state.updateCurrentAwsUser,
    getCurrentUser: state.getCurrentUser.userData,
  };
};

export default connect(mapStateToProps)(ProfileLayout);
