
const initialState = {
  customer: {}
}

const selectedCustomer = (state = initialState, action) => {
  const newState = { ...state }
  if (action.type === 'CUSTOMER_SELECTED') {
    newState.customer = action.data
  }

  return newState
};


export default selectedCustomer
