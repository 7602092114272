/* eslint-disable */
import React, { Component } from "react";
import { Auth } from 'aws-amplify';
import styles from '../../AuthStyling'

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            usernameEntered: false,

            code: '',
            codeEntered: false,

            newPassword: '',
            newPasswordEntered: false,

            successMsg: ''
        };
    }

    userNameHandle = (event) => {
        this.setState({
            username: event.target.value,
        })
    }

    confirmationCodeHandle = (event) => {
        this.setState({
            code: event.target.value,
        })
    }

    newPasswordHandle = (event) => {
        this.setState({
            newPassword: event.target.value,
        })
    }


    handleSubmit = async (event) => {
        event.preventDefault();
        const { username } = this.state

        Auth.forgotPassword(username)
            .then(data => {
                this.setState({
                    usernameEntered: true,
                })
            })
            .catch(err => console.log("error:", err));

    }

    handleSubmit2 = async (event) => {
        event.preventDefault();
        const { code } = this.state

        if (code.length > 5) {
            this.setState({
                codeEntered: true,
            })
        }
    }

    handleSubmit3 = async (event) => {
        event.preventDefault();
        const { username, code, newPassword, codeEntered } = this.state

        if (newPassword && codeEntered) {
            // // Collect confirmation code and new password, then
            Auth.forgotPasswordSubmit(username, code, newPassword)
                .then(data => {
                    console.log('update password:', data)
                    this.setState({
                        newPasswordEntered: true,
                        successMsg: data
                    })
                    setTimeout(window.location.reload(true), 5000);
                })
                .catch(err => console.log("error:", err));
        }
    }

    render() {
        const { code, username, newPassword, usernameEntered, codeEntered, newPasswordEntered, successMsg } = this.state
        return (
            <div>
                <div>
                    {usernameEntered && codeEntered && newPasswordEntered ? successMsg : ''}
                </div>

                {!usernameEntered ?
                    <form onSubmit={this.handleSubmit} >
                        <br />
                        <label>
                            <input style={styles.input} type="text" value={username} onChange={this.userNameHandle} placeholder='Enter Email' />
                        </label>
                        <br />
                        <button style={styles.button} type="submit" value="Submit">Submit</button>
                    </form>
                    : ''}

                {usernameEntered && !codeEntered
                    ? <form onSubmit={this.handleSubmit2}>
                        <br />
                        <label>
                            <input style={styles.input} type="text" value={code} onChange={this.confirmationCodeHandle} placeholder='Enter confirmation code' />
                        </label>

                        <br />
                        <button style={styles.button} type="submit" value="Submit">Submit</button>
                    </form>
                    : ''}

                {usernameEntered && codeEntered && !newPasswordEntered ?
                    <form onSubmit={this.handleSubmit3}>
                        <br />
                        <label>
                            <input style={styles.input} type="text" value={newPassword} onChange={this.newPasswordHandle} placeholder='Enter new password' />
                        </label>
                        <br />
                        <button style={styles.button} type="submit" value="Submit">Submit</button>
                    </form>
                    : ''}

            </div>
        );
    }
}

export default ResetPassword
