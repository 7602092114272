/* eslint-disable */
import React, { useEffect, useState, useReducer } from "react";
import { Auth, Hub } from 'aws-amplify';
import styles from "./AuthStyling";
import googleLogo from './googleLogo.png'
import Gateway from './components/admin/gateway'
import ResetPassword from './components/new_password/reSetPassword'

const initialFormState = {
  username: "",
  password: "",
  email: "",
  confirmationCode: "",
};

async function signInGoogle() {
  try {
    await Auth.federatedSignIn({ provider: "Google" });
    console.log("sign in with google success!!!");
  } catch (err) {
    console.log("error signing up..", err);
  }
}

function updateFormStateReducer(state, action) {
  switch (action.type) {
    case "updateFormState":
      return {
        ...state,
        [action.e.target.name]: action.e.target.value,
      };
    default:
      return state;
  }
}

function checkUser() {
  Auth.currentAuthenticatedUser()
    .then((user) =>
      console.log(user, 'GROUP =>', user.signInUserSession.accessToken.payload["cognito:groups"])
    )
    .catch((err) => console.log('error with checking user', err));
}

// admin use only
// function addUserAsDyper() {
//   Auth.currentAuthenticatedUser()
//     .then((user) =>
//       console.log(user, 'GROUP =>', user.signInUserSession.accessToken.payload["cognito:groups"])
//     )
//     .catch((err) => console.log('error with adding dyper user', err));

//   Auth.currentAuthenticatedUser()
//     .then((user) => {
//       if (user) {
//         Auth.updateUserAttributes(user, {
//           "custom:isDyperEmployee": 'true',
//         });
//         console.log('custom:isDyperEmployee updated')
//       }
//     })
//     .catch((err) => console.log('error with currentAuthenticatedUser', err));
// }

async function signUp({ username, password, email }, updateFormType) {
  try {
    await Auth.signUp({
      username,
      password,
      attributes: { email },
    });
    console.log("sign up success!");
    updateFormType("confirmSignUp");
  } catch (err) {
    console.log("error signing up..", err);
  }
}

async function confirmSignUp({ username, confirmationCode }, updateFormType) {
  try {
    await Auth.confirmSignUp(username, confirmationCode);
    console.log("confirm sign up success!");
    updateFormType("signIn");
  } catch (err) {
    console.log("error signing up..", err);
  }
}

function SignIn(props) {
  return (
    <div className="container" >
      <div className="">
        <div className="row text-center">
          {/* <div className="col full_page_bootstrap"></div> */}
          <div className="col" style={styles.center_auth_form}
          >
            <div>
              <br />
              <h1 style={styles.home_title_font}>Dyper</h1>
              <br />
              <div>
                <input
                  name="username"
                  onChange={(e) => {
                    e.persist();
                    props.updateFormState(e);
                  }}
                  style={styles.input}
                  placeholder="Username or Email"
                />
              </div>
              <div>
                <input
                  type="password"
                  name="password"
                  onChange={(e) => {
                    e.persist();
                    props.updateFormState(e);
                  }}
                  style={styles.input}
                  placeholder="Password"
                />
              </div>
              <div>
                <br />
                <button style={styles.button} onClick={props.signIn}>
                  Sign In
                </button>
              </div>
              <div>
                <button
                  className="border"
                  style={styles.button_google}
                  onClick={signInGoogle}
                >
                  {/* <img style={styles.google_icon} src={googleLogo} alt="Logo" /> */}
                  <span className="white_color">{"-"}</span>
                  {/* Sign In with Google */}
                  Recyclops
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SignUp(props) {
  return (
    <div>
      <div className="row text-center">
        <div className="col full_page_bootstrap">{""}</div>
        <div
          className="col row justify-content-center"
          style={styles.center_auth_form}
        >
          <div>
            <h1 style={styles.home_title_font}>Create your account</h1>
            <br />
            <br />
            <br />
            <div>
              <input
                name="username"
                onChange={(e) => {
                  e.persist();
                  props.updateFormState(e);
                }}
                style={styles.input}
                placeholder="Username"
              />
            </div>
            <div>
              <input
                name="email"
                onChange={(e) => {
                  e.persist();
                  props.updateFormState(e);
                }}
                style={styles.input}
                placeholder="Email"
              />
            </div>
            <div>
              <input
                type="password"
                name="password"
                onChange={(e) => {
                  e.persist();
                  props.updateFormState(e);
                }}
                style={styles.input}
                placeholder="Password"
              />
            </div>
            <div>
              <br />
              <button style={styles.button} onClick={props.signUp}>
                Sign Up
              </button>
            </div>
            <div>
              <button
                className="google_buttom_border border"
                style={styles.button_google}
                onClick={signInGoogle}
              >
                <img style={styles.google_icon} src={googleLogo} alt="Logo" />
                <span className="white_color">{"-"}</span>
                Sign up with Google
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ConfirmSignUp(props) {
  return (
    <div style={styles.container}>
      <input
        name="confirmationCode"
        placeholder="Confirmation Code"
        onChange={(e) => {
          e.persist();
          props.updateFormState(e);
        }}
        style={styles.input}
      />
      <button onClick={props.confirmSignUp} style={styles.button}>
        Confirm Sign Up
      </button>
    </div>
  );
}

function App() {
  const [isEmployee, setEmployeeAccess] = useState(false);
  const [forgotPasswordClicked, setforgotPassword] = useState(false);

  const [authState, setAuthState] = useState("");
  const [user, setUser] = useState();
  const [formType, updateFormType] = useState("signIn");
  // const [formType, updateFormType] = useState("signUp");
  const [formState, updateFormState] = useReducer(
    updateFormStateReducer,
    initialFormState
  );

  // in useEffect, we create the listener
  useEffect(() => {
    Hub.listen("auth", (data) => {
      const { payload } = data;

      if (payload.event === "signIn") {
        setAuthState("signedIn");
      }
      if (payload.event === "signOut") {
        setAuthState(payload.event);
      }
    });

    if (authState === "signedIn") {
      updateFormType("signedIn");
    }

    if (authState === "signOut") {
      updateFormType("signOut");
    }

    Auth.currentAuthenticatedUser()
      .then((user) => setUser({ user }, setAuthState("signedIn")))
      .catch((err) => console.log('error with login', err));

  }, []);

  async function signIn(username, password) {
    const user = await Auth.signIn(username, password);
    setAuthState("signedIn");
  }

  async function forgot_Password(username, password) {
    setforgotPassword(!forgotPasswordClicked);
  }

  function renderForm() {
    switch (formType) {
      case "signUp":
        return (
          <SignUp
            signUp={() => signUp(formState, updateFormType)}
            updateFormState={(e) =>
              updateFormState({ type: "updateFormState", e })
            }
          />
        );
      case "confirmSignUp":
        return (
          <ConfirmSignUp
            confirmSignUp={() => confirmSignUp(formState, updateFormType)}
            updateFormState={(e) =>
              updateFormState({ type: "updateFormState", e })
            }
          />
        );
      case "signIn":
        return (
          <SignIn
            signIn={() => signIn(formState)}
            updateFormState={(e) =>
              updateFormState({ type: "updateFormState", e })
            }
          />
        );
      default:
        return null;
    }
  }
  return (
    <div >

      {forgotPasswordClicked
        ? <div onClick={forgot_Password} style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: '#2A9B8D',
          fontWeight: 'bold'
        }}>{
            forgotPasswordClicked
              ? `Login Page`
              : `Forgot Password`
          }</div>
        : ''}

      {forgotPasswordClicked
        ? <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <ResetPassword />
        </div>
        :
        <div>
          {authState === "signedIn" ? (
            <div>
              <Gateway
                isEmployee={isEmployee}
                checkUser={checkUser}
              />
            </div>
          ) : (
            <div>
              <div >{renderForm(formState)}</div>
              <div>
                <br />
                <div onClick={forgot_Password} style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: '#2A9B8D',
                  fontWeight: 'bold'
                }}>{
                    forgotPasswordClicked
                      ? `Login Page`
                      : `Forgot Password`
                  }</div>
              </div>
            </div>
          )}
        </div>
      }



    </div>
  );
}

export default App

{/* {isEmployee
            ? <div>
              <Layout checkUser={checkUser} />
              <div>{console.log('isEmployee', isEmployee)}</div>
            </div>
            :
            <div>
              <NotRecyclopsEmployee />
              <button onClick={checkUser}>Check User Status</button>
              <div>{console.log('isEmployee', isEmployee)}</div>
            </div>
          } */}

{/* <div className="">
            <div className="row text-center">
              <div className="col">{""}</div>
              <div className="col">
                <div>
                  {formType === "signIn" && (
                    <p style={styles.signin_link}>
                      Already have an account?{" "}
                      <span
                        style={styles.anchor}
                        onClick={() => updateFormType("signUp")}
                      >
                        Sign In
                      </span>
                    </p>
                  )}
                  <br />
                  {formType === "signIn" && (
                    <p style={styles.signup_link}>
                      Don't have an account?{" "}
                      <span
                        style={styles.anchor}
                        onClick={() => updateFormType("signUp")}
                      >
                        Sign Up
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div> */}

    // This was moved to Gateway component
    // check if user is an Recyclops or Dyper employee
    // Auth.currentAuthenticatedUser()
    //   .then((user) => {
    //     console.log('=======', user)
    //     if (user.signInUserSession.accessToken.payload["cognito:groups"][1] === process.env.REACT_APP_EMPLOYEE_GROUP
    //       || user.signInUserSession.accessToken.payload["cognito:groups"][0] === process.env.REACT_APP_EMPLOYEE_GROUP
    //       || user.signInUserSession.accessToken.payload["cognito:groups"][0] === process.env.REACT_APP_DYPER_EMPLOYEE_GROUP
    //       || user.attributes['custom:isDyperEmployee'] === 'true'
    //     ) {
    //       setEmployeeAccess(true)
    //     }
    //   })
    //   .catch((err) => console.log('error with login', err));

    // Auth.currentAuthenticatedUser()
    //   .then((user) =>
    //     console.log(user, 'GROUP =>', user.signInUserSession.accessToken.payload["cognito:groups"])
    //   )
    //   .catch((err) => console.log('===', err));

    // Auth.currentAuthenticatedUser()
    //   .then((user) => {
    //     if (user) {
    //       Auth.updateUserAttributes(user, {
    //         "custom:isDyperEmployee": 'true',
    //       });
    //       console.log('custom:isDyperEmployee updated')
    //     }
    //   })
    //   .catch((err) => console.log('error with currentAuthenticatedUser', err));
