/* eslint-disable */
import React, { Component } from "react";
import { Auth, Hub } from 'aws-amplify';
import Layout from './components/layout/layout'
import ResetPassword from './components/new_password/reSetPassword'
import styles from './AuthStyling'

class LoginScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuth: false,
            isDyperEmployee: false,
            userName: '',
            password: '',
            loading: true,
            resetPassword: false,
            userExist: false,
            handleSubmitClicked: false,
        };
    }
    async componentDidMount() {
        Hub.listen('auth', this.listener);

        Auth.currentAuthenticatedUser()
            .then((user) => {
                this.setState({ loading: false, isAuth: true });
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    listener = (data) => {
        switch (data.payload.event) {
            case 'signIn':
                this.setState({ isAuth: true });
                break;
            case 'signOut':
                this.setState({ isAuth: false });
                break;
            default:
                break;
        }
    }

    signInGoogle = async () => {
        const google = await Auth.federatedSignIn({ provider: "Google" });
    }

    signIn = async (username, password) => {

        setTimeout(() => {
            this.setState({
                handleSubmitClicked: true
            })
        }, 1500);

        try {
            const user = await Auth.signIn(username, password)
            this.setState({
                userExist: true,
            })
        } catch (e) {
            this.setState({
                userExist: false,
            })
        }
    }

    userNameHandle = (e) => {
        this.setState({
            userName: e.target.value
        })
    }

    passwordHandle = (e) => {
        this.setState({
            password: e.target.value
        })
    }

    signOut = async () => {
        const user = await Auth.signOut();
        this.setState({
            isAuth: false
        })
    }

    checkUser = () => {
        Auth.currentAuthenticatedUser()
            .then((user) =>
                console.log(user, 'GROUP =>', user.signInUserSession.accessToken.payload["cognito:groups"])
            )
            .catch((err) => console.log('error with checking user', err));
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const { setAuthState, userName, password } = this.state

        if (userName && password) {
            this.signIn(userName, password)
        }
    }

    resetPasswordHandle = () => {
        this.setState({
            resetPassword: true
        })
    }

    cancelResetPasswordHandle = () => {
        this.setState({
            resetPassword: false
        })
    }

    render() {
        const { isAuth, password, userName, resetPassword, userExist, handleSubmitClicked } = this.state
        return (
            <div>
                {!isAuth
                    ? <div className="row text-center">
                        <div className="col-sm" style={styles.welcomePageLayoutLeft}>
                            <div className="" style={styles.welcomePageLayoutLeftFont}>
                                <div>
                                    Partner Portal
                                </div>
                            </div>
                        </div>
                        <div className="col-sm" style={styles.welcomePageLayoutRight}>
                            <div>{!isAuth
                                ? <div>{resetPassword
                                    ? <div>
                                        <div>
                                            <ResetPassword />
                                            <div>
                                                <div style={styles.forGotPasswordStyling} onClick={this.cancelResetPasswordHandle}>Cancel</div>
                                            </div>
                                        </div>
                                    </div>
                                    : <div>
                                        <form onSubmit={this.handleSubmit}>

                                            <div>
                                                <input style={styles.input} name="username" placeholder="Username" type="text" value={userName} onChange={this.userNameHandle} />
                                            </div>
                                            <div>
                                                <input style={styles.input} name="password" placeholder="Password" type="password" value={password} onChange={this.passwordHandle} />
                                            </div>
                                            <div>
                                                <div style={styles.forGotPasswordStyling} className="text-right" onClick={this.resetPasswordHandle}>Forgot Password?</div>
                                            </div>
                                            <br />
                                            <button style={styles.button} type="submit" value="Sign In">Sign In</button>
                                        </form>
                                        <div>
                                            <button style={styles.button_google} onClick={this.signInGoogle}>Recyclops</button>
                                        </div>
                                        <div style={{ color: 'red' }}>
                                            {handleSubmitClicked
                                                ? userExist ? '' : 'Invalid Username and/or Password entered'
                                                : ''
                                            }
                                        </div>
                                    </div>
                                }
                                </div>
                                : 'Error'
                            }
                            </div>

                        </div>
                    </div >
                    : <Layout />
                }

            </div>

        );
    }
}

export default LoginScreen
