export async function fetchPickUpSingleCustomer(id, token) {
    try {

        const result = await fetch(`${process.env.REACT_APP_FETCH_ONE_PICKUPS}${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': `Bearer ${token}`,
            },
            'credentials': 'include'
        });

        const data = await result.json();
        return data;

    } catch (e) {
        return null;
    }
}
