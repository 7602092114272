import { combineReducers } from "redux";
import counter from '../../store/counter'
import getCurrentUser from '../../store/getCurrentUser'
import locationsApi from '../../store/locationsApi'
import selectedCustomer from '../../store/selectedCustomer'


const combinedReducers = combineReducers({
    counter,
    getCurrentUser,
    locationsApi,
    selectedCustomer
});

export default combinedReducers