/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { fetchCustomers } from '../helpers/api_services/fetchCustomers'
import { fetchOneCustomer } from '../helpers/api_services/fetchOneCustomer'
import { fetchSearchCustomers } from '../helpers/api_services/fetchSearchCustomers'
import { fetchNextCustomers } from '../helpers/api_services/fetchNextCustomers'
import { fetchCustomersCurrent } from '../helpers/api_services/fetchCustomersCurrent'
import { fetchCustomersHistory } from '../helpers/api_services/fetchCustomersHistory'

import CustomerTable from './customerTable';
import SingleCustomerData from './singleCustomerData'

import FileSaver from 'file-saver';

import { connect } from "react-redux";

import download from '../../download.png'
import search from '../../Search.png'

import tableStyling from '../table_styling/tableStyling'

import months from '../helpers/api_services/months'
import moment from 'moment'

import { BallTriangle } from 'react-loader-spinner'

import useInfiniteScroll from "../helpers/api_services/useInfiniteScroll";

function CustomerData({ currentCustomer, customerSelected, getCurrentUser }) {

    const [searchTerm, setSearchTerm] = useState('');
    const [customersArr, setCustomersArr] = useState([]);
    const [selectedCustomer, setselectedCustomer] = useState({});
    const [customerSelectedBool, setCustomerSelectedBool] = useState(false);
    const [customerAlreadySelectedFromPickup, setcustomerAlreadySelectedFromPickup] = useState(false);
    const [searchBarClicked, setSearchBarClicked] = useState(false);
    const [downLoadData, setDownLoadData] = useState([]);
    const [prevOrCurrentToggle, setPrevOrCurrentToggle] = useState('showAll');
    const [token, setToken] = useState('');
    const [isLoadingMainTable, setIsLoadingMainTable] = useState(true);

    const [nextLink, setNextLink] = useState('');
    const [nextLinkBool, setNextLinkBool] = useState(true);

    const [isFetching, setIsFetching] = useState(false);
    const [isPaginating, setIsPaginating] = useState(true);

    const [customersArrLength, setCustomersArrLength] = useState(0);

    const [lastElementRef] = useInfiniteScroll(
        isPaginating ? loadMoreItems : () => { },
        isFetching
    );

    useEffect(() => {
        loadMoreItems();

        setPrevOrCurrentToggle('showAll')
        setIsFetching(false)
        setIsPaginating(true)

        setNextLink('')
        setNextLinkBool(false)
        setSearchTerm('')
        setCustomersArr([])
        setselectedCustomer({})
        setCustomerSelectedBool(false)
        setcustomerAlreadySelectedFromPickup(false)
        setSearchBarClicked(false)
        setDownLoadData([])
        setToken('')
        setIsLoadingMainTable(true)
        setCustomersArrLength(0)

        //when this.state.errors object is empty 
        if (Object.keys(currentCustomer).length == 0) {
            setCustomersArr([])
            fetchDyperCustomers()
        } else {
            setselectedCustomer(currentCustomer)
            setcustomerAlreadySelectedFromPickup(true)
            setCustomersArr([])
            fetchDyperCustomers()
        }

    }, [])

    async function fetchDyperCustomers() {
        const token = getCurrentUser

        setToken(token)

        const dyperCustomers = await fetchCustomers(token)

        if (dyperCustomers) {
            setIsLoadingMainTable(false)
            if (dyperCustomers.Customers) {
                setCustomersArr([...dyperCustomers.Customers])
                setCustomersArrLength(dyperCustomers.Customers.length)
            }
            if (dyperCustomers.Next) {
                setNextLink(dyperCustomers.Next)
            } else {
                setNextLink('')
            }
        }
    }

    async function loadMoreItems() {
        const token = getCurrentUser
        setIsFetching(true);
        let updatedCustomerArray = []

        if (nextLink.length > 0) {
            let data = await fetchNextCustomers(token, nextLink)

            if (data) {
                if (data.Customers.length > 0) {
                    updatedCustomerArray = [...customersArr, ...data.Customers]
                    setCustomersArr(updatedCustomerArray)
                }

                if (data.Next) {
                    setIsPaginating(true)
                    setNextLink(data.Next)
                } else {
                    setNextLink('')
                    setNextLinkBool(false)
                    setIsPaginating(false)
                }

                setIsFetching(false);
            }

        }

    }

    const setCurrentHandle = async () => {
        setPrevOrCurrentToggle('showCurrent')
        setSearchTerm('')
        setIsLoadingMainTable(true)
        const token = getCurrentUser

        let today = new Date();
        let yesterday = new Date(today);

        let dd = yesterday.getDate();
        let mm = 0
        let yyyy = yesterday.getFullYear();

        for (let key in months) {
            if (yesterday.toDateString().split(' ')[1] === key) {
                mm = months[key]
            }
        }

        let daysDate = yyyy + '-' + (mm) + '-' + (dd < 10 ? '0' + dd : dd)
        let fetchCustomersByTodaysDate = await fetchCustomersCurrent(token, daysDate)

        if (fetchCustomersByTodaysDate) {
            if (fetchCustomersByTodaysDate.Customers) {
                setCustomersArr([...fetchCustomersByTodaysDate.Customers])
                setCustomersArrLength(fetchCustomersByTodaysDate.Customers.length)
                setIsLoadingMainTable(false)
            }
        }
    }

    const setPrevHandle = async () => {
        setPrevOrCurrentToggle('showPrev')
        setSearchTerm('')
        setIsLoadingMainTable(true)

        const token = getCurrentUser

        let today = new Date();
        let yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        let dd = yesterday.getDate();
        let mm = 0
        let yyyy = yesterday.getFullYear();

        for (let key in months) {
            if (yesterday.toDateString().split(' ')[1] === key) {
                mm = months[key]
            }
        }

        let yesterdayDate = yyyy + '-' + (mm) + '-' + (dd < 10 ? '0' + dd : dd)

        if (yesterdayDate) {
            let fetchCustomersPrevHistory = await fetchCustomersHistory(token, yesterdayDate)
            if (fetchCustomersPrevHistory.Customers) {
                setCustomersArr([...fetchCustomersPrevHistory.Customers])
                setCustomersArrLength(fetchCustomersPrevHistory.Customers.length)
                setIsLoadingMainTable(false)
            }
        }
    }

    const setAllHandle = () => {
        setPrevOrCurrentToggle('showAll')
        setSearchTerm('')

        setIsLoadingMainTable(true)
        fetchDyperCustomers()
        setTimeout(function () {
            setIsLoadingMainTable(false)
        }, 600);
    }

    const searchBarClickedHandle = () => {
        // setSearchTerm('');
        // customerSelected({})
        // setselectedCustomer({})
        // setCustomerSelectedBool(false)
        // setcustomerAlreadySelectedFromPickup(false)
        setSearchBarClicked(!searchBarClicked)
    }

    const fetchSingleCustomer = async (id) => {
        const token = getCurrentUser
        let customer = await fetchOneCustomer(id, token)

        setselectedCustomer(customer)
        setCustomerSelectedBool(true)
    }

    const toggleBackToAllCustomers = () => {
        customerSelected({})
        setselectedCustomer({})
        setCustomerSelectedBool(false)
        setcustomerAlreadySelectedFromPickup(false)
    }

    const searchHandle = async event => {
        const token = getCurrentUser
        let searchValue = event.target.value

        // setCustomersArr([])
        // setCustomersArrLength(0)

        setSearchTerm(searchValue);


        if (searchValue.length > 2) {
            let searchCustomerArr = await fetchSearchCustomers(token, searchValue)

            setTimeout(() => {
                if (searchCustomerArr.Customers) {
                    setCustomersArr([...searchCustomerArr.Customers])
                    setCustomersArrLength(searchCustomerArr.Customers.length)
                }
            }, 500);

        } else if (searchValue.length === 0) {

            setIsLoadingMainTable(true)

            const dyperCustomers = await fetchCustomers(token)

            if (dyperCustomers) {
                setIsLoadingMainTable(false)

                if (dyperCustomers.Customers) {
                    setCustomersArr([...dyperCustomers.Customers])
                    setCustomersArrLength(dyperCustomers.Customers.length)
                }
            }
            setSearchTerm('');
        }

    };

    const downloadhandle = () => {

        let arr = []

        let datetime = new Date().toISOString()
        let year = datetime.substring(0, 10).split('-')[0]
        let month = datetime.substring(0, 10).split('-')[1]
        let day = datetime.substring(0, 10).split('-')[2]

        let todaysDate = `${month}/${day}/${year}`

        arr = []
        // 
        if (customersArr.length > 0) {
            if (searchTerm.length > 0) {

                customersArr.map((data, i) => {
                    // https://stackabuse.com/compare-two-dates-in-javascript/
                    let datetime = data.created_date
                    let year = datetime.substring(0, 10).split('-')[0]
                    let month = datetime.substring(0, 10).split('-')[1]
                    let day = datetime.substring(0, 10).split('-')[2]
                    let timeStamp = `${month}-${day}-${year}`

                    if (prevOrCurrentToggle === 'showCurrent') {
                        arr.push(data)
                    }
                    if (prevOrCurrentToggle === 'showPrev') {
                        arr.push(data)
                    }
                    if (prevOrCurrentToggle === 'showAll') {
                        arr.push(data)
                    }

                })

            } else {

                for (let i = 0; i < customersArr.length; i++) {
                    let data = customersArr[i]
                    // https://stackabuse.com/compare-two-dates-in-javascript/
                    let datetime = data.created_date
                    let year = datetime.substring(0, 10).split('-')[0]
                    let month = datetime.substring(0, 10).split('-')[1]
                    let day = datetime.substring(0, 10).split('-')[2]
                    let timeStamp = `${month}/${day}/${year}`

                    if (prevOrCurrentToggle === 'showCurrent') {
                        arr.push(data)
                    }
                    if (prevOrCurrentToggle === 'showPrev') {
                        arr.push(data)
                    }
                    if (prevOrCurrentToggle === 'showAll') {
                        arr.push(data)
                    }
                }

            }
            //
            setDownLoadData(arr)

            let dataBody = `Customer Name,Street Name,City,State,Created Date,Pickup Day,Pickup Freqency,Status`

            if (arr.length > 0) {
                for (let i = 0; i < arr.length; i++) {
                    let data = arr[i]
                    // 
                    let currentTimeZone = new Date(datetime).toString()

                    let monthIntoNum = ''
                    let convertMilitaryTime = ''
                    let currentTimeZoneShortened = ''

                    if (datetime) {
                        for (let key in months) {
                            if (key === currentTimeZone.split(' ')[1]) {
                                monthIntoNum = months[key]
                            }
                        }
                        convertMilitaryTime = moment(currentTimeZone.split(' ')[4], 'HH:mm:ss').format('h:mm:ss A')
                        currentTimeZoneShortened = `${monthIntoNum}-${currentTimeZone.split(' ')[2]}-${currentTimeZone.split(' ')[3]}-${convertMilitaryTime}`
                    }
                    // 

                    let address = data.full_address.split(',')
                    let name = `${data.firstname} ${data.lastname}`
                    let fullAddress = data.full_address
                    let streetAddress = address[0]
                    let city = address[1]
                    let stateAndZip = address[2]
                    let country = address[3]
                    let pickupDay = data.pickup_day
                    let pickupFreq = data.pickup_freq
                    let record_status = data.record_status

                    dataBody += `\n ${name},${streetAddress}, ${city}, ${stateAndZip},${currentTimeZoneShortened},${pickupDay},${pickupFreq},${record_status}`

                }
            }
            let blob = new Blob([dataBody], { type: "text/plain;charset=utf-8" });

            FileSaver.saveAs(blob, "Recyclops dyper customer data.csv");

        } else {
            console.log('no data')
        }
    }

    return (
        <div style={tableStyling.pagePadding} >
            <div className="content-wrapper">
                <div className="">
                    <div className="container-fluid">
                        <div className={customerAlreadySelectedFromPickup ? '' : ''}>
                            <div style={tableStyling.customerContainer}>
                                <div >
                                    <div >
                                        <div style={{ paddingLeft: '1.8%' }}>

                                            <div className="" style={customerSelectedBool ? tableStyling.cardStyling : { backgroundColor: '' }}>
                                                <div>
                                                    <div>{
                                                        !customerAlreadySelectedFromPickup
                                                            ? <div>
                                                                <div>{
                                                                    !customerSelectedBool
                                                                        ?
                                                                        <div className="" >
                                                                            <div>
                                                                                <div className=''>
                                                                                    {!searchBarClicked
                                                                                        ? <div className="row">
                                                                                            <div className="col-6 ">
                                                                                                <div style={tableStyling.pageTitleFont}>
                                                                                                    {customerSelectedBool
                                                                                                        ? <div onClick={() => toggleBackToAllpickups()} style={tableStyling.pageTitleSize}>
                                                                                                            <span type="button" className='btn'>{`< Customer Management`}</span>
                                                                                                        </div>
                                                                                                        : <div style={tableStyling.pageTitleSize}>{'Customer Management'}</div>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-6 text-right" style={tableStyling.pageTitleFont}>
                                                                                                <div className="row" style={{ paddingTop: '1%' }}>
                                                                                                    <div className="col"></div>
                                                                                                    <div className="col-sm"></div>
                                                                                                    <div className="col">
                                                                                                        <div className="row" >
                                                                                                            <div className="col-6"></div>
                                                                                                            <div className="col-3 text-right">
                                                                                                                <img
                                                                                                                    style={tableStyling.searchIcon}
                                                                                                                    id='searchButton' onClick={searchBarClickedHandle}
                                                                                                                    src={search}
                                                                                                                    alt="pickupImage" />
                                                                                                            </div>
                                                                                                            <div className="col-3 text-left">
                                                                                                                <img
                                                                                                                    style={tableStyling.downloadIcon}
                                                                                                                    id='toDownload' onClick={() => downloadhandle()}
                                                                                                                    src={download}
                                                                                                                    alt="pickupImage" />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        : <div style={{ paddingBottom: '.4%' }}>
                                                                                            <div className="row">
                                                                                                <div className="col-6">
                                                                                                    <div className="" style={tableStyling.pageTitleFont}>
                                                                                                        {customerSelectedBool
                                                                                                            ? <div onClick={() => toggleBackToAllpickups()} style={tableStyling.pageTitleSize}>
                                                                                                                {`< Customer Management`}
                                                                                                            </div>
                                                                                                            : <div style={tableStyling.pageTitleSize}>{'Customer Management'}</div>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-6 text-right" style={tableStyling.pageTitleFont}>
                                                                                                    <div className="row" >
                                                                                                        <div className="col-1">
                                                                                                            <img
                                                                                                                style={tableStyling.searchIcon}
                                                                                                                id='searchButtonClicked' onClick={searchBarClickedHandle}
                                                                                                                src={search}
                                                                                                                alt="pickupImage" />
                                                                                                        </div>
                                                                                                        <div className="col-10">
                                                                                                            <div className="col">
                                                                                                                <span>
                                                                                                                    <input
                                                                                                                        style={tableStyling.searchInput}
                                                                                                                        type='text' placeholder=' Search for customer, name, state, etc...'
                                                                                                                        value={searchTerm} onChange={(e) => searchHandle(e)} />
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-1">
                                                                                                            <div className="row">
                                                                                                                <div className="col text-left">
                                                                                                                    <img
                                                                                                                        style={tableStyling.downloadIcon}
                                                                                                                        id='toDownload' onClick={() => downloadhandle()}
                                                                                                                        src={download}
                                                                                                                        alt="pickupImage" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                </div>

                                                                                <div style={{ padding: '.2%' }}></div>
                                                                                <div className="col-12" style={tableStyling.cardStyling}>
                                                                                    <div>
                                                                                        <div className="row text-center">
                                                                                            <div className="col btn" onClick={setPrevHandle} style={prevOrCurrentToggle === 'showPrev'
                                                                                                ? tableStyling.clickedHistoryTab
                                                                                                : tableStyling.notClickedHistoryTab
                                                                                            }>
                                                                                                <div style={tableStyling.headerTabStyling}>History</div>
                                                                                            </div>
                                                                                            <div className="col btn" onClick={setCurrentHandle} style={prevOrCurrentToggle === 'showCurrent'
                                                                                                ? tableStyling.clickedCurrentTab
                                                                                                : tableStyling.notClickedCurrentTab
                                                                                            }>
                                                                                                <div style={tableStyling.headerTabStyling}>Today</div>
                                                                                            </div>

                                                                                            <div className="col btn" onClick={setAllHandle} style={prevOrCurrentToggle === 'showAll'
                                                                                                ? tableStyling.clickedAllTab
                                                                                                : tableStyling.notClickedAllTab
                                                                                            }>
                                                                                                <div style={tableStyling.headerTabStyling}>All Data</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div style={tableStyling.scrollBarStylingCustomerTable}>
                                                                                        {isLoadingMainTable
                                                                                            ? <div className="col-12" style={tableStyling.isLoadingSpinner}>
                                                                                                <BallTriangle
                                                                                                    heigth="100"
                                                                                                    width="100"
                                                                                                    color="#FCC816"
                                                                                                    ariaLabel="loading-indicator"
                                                                                                />
                                                                                            </div>
                                                                                            : <CustomerTable
                                                                                                customersArrLength={customersArrLength}
                                                                                                isPaginating={isPaginating}
                                                                                                isFetching={isFetching}
                                                                                                lastElementRef={lastElementRef}
                                                                                                loadMoreItems={loadMoreItems}
                                                                                                customersArr={customersArr}
                                                                                                searchTerm={searchTerm}
                                                                                                fetchSingleCustomer={fetchSingleCustomer}
                                                                                                setPrevHandle={setPrevHandle}
                                                                                                prevOrCurrentToggle={prevOrCurrentToggle}
                                                                                                setCurrentHandle={setCurrentHandle}
                                                                                                setPrevHandle={setPrevHandle}
                                                                                                setAllHandle={setAllHandle}

                                                                                                searchBarClicked={searchBarClicked}
                                                                                                searchBarClickedHandle={searchBarClickedHandle}
                                                                                                searchHandle={searchHandle}
                                                                                                downloadhandle={downloadhandle}
                                                                                            />
                                                                                        }
                                                                                    </div>
                                                                                    <br />
                                                                                    <br />
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        :
                                                                        <div className="col-12"  >
                                                                            <div>
                                                                                <SingleCustomerData
                                                                                    getCurrentUser={getCurrentUser}
                                                                                    toggleBack={toggleBackToAllCustomers}
                                                                                    selectedCustomer={selectedCustomer} />
                                                                            </div>
                                                                        </div>
                                                                }
                                                                </div>
                                                            </div>
                                                            : <div>
                                                                <SingleCustomerData
                                                                    getCurrentUser={getCurrentUser}
                                                                    toggleBack={toggleBackToAllCustomers}
                                                                    selectedCustomer={selectedCustomer} />
                                                                <br />
                                                            </div>

                                                    }</div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        currentCustomer: state.selectedCustomer.customer,
        getCurrentUser: state.getCurrentUser.jwtToken
    };
};

const mapDispachToProps = (dispach) => {
    return {
        customerSelected: (state) => { dispach({ type: 'CUSTOMER_SELECTED', data: state }) },
    };
}

export default connect(mapStateToProps, mapDispachToProps)(CustomerData)

