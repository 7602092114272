/* eslint-disable */
import React, { Component } from "react";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    // const { menuCollapsed } = this.props;
    return (
      <div>
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <div
            className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-10">
                  <h1 className="m-0">Settings Page</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Settings
