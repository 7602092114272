/* eslint-disable */
import React, { Component } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
    // width: '350px',
    // height: 345,
    // height: '100vh', 
    height: '33vh',
    width: '30vh'
};
const containerStylePickupData = {
    // width: '350px',
    // height: 305
    height: '33vh',
    width: '30vh'
};

class MapPinDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            todaysDate: '',
            latLng: {
                lat: 33.812511,
                lng: -117.918976
            },
            noLatLng: false,
            refresh: false
        };
    }

    async componentDidMount() {
        const { customerLatLng } = this.props
        const { latLng } = this.state

        if (customerLatLng.lat === latLng.lat && customerLatLng.lng === latLng.lng) {
            this.setState({
                noLatLng: true,
                latLng: {
                    lat: latLng.lat,
                    lng: latLng.lng
                },
            })
        } else {
            this.setState({
                noLatLng: false
            })
        }
    }

    async componentWillUnmount() {
        this.setState({
            todaysDate: '',
            latLng: {
                lat: 33.812511,
                lng: -117.918976
            },
        })
    }

    render() {
        const { customerLatLng, page } = this.props
        const { latLng, noLatLng } = this.state

        return (
            <div >
                <LoadScript
                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                >
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={customerLatLng}
                        zoom={15}
                    >
                        <Marker position={customerLatLng}></Marker>
                        <></>
                    </GoogleMap>
                    {
                        <div className='text-center' style={{ fontWeight: 'bold', fontSize: '1.6vh', }}>{
                            customerLatLng.lat === 33.812511 && customerLatLng.lng === -117.918976 ?
                                'Location not available'
                                : <div style={{ color: 'white' }}>{'data'}</div>
                        }</div>
                    }
                </LoadScript>
            </div>
        )
    }
}


export default MapPinDisplay
