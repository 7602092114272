export async function fetchSearchPickups(token, searchValue) {
    try {
        const result = await fetch(`${process.env.REACT_APP_FETCH_ALL_PICKUPS}?q=${searchValue}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': `Bearer ${token}`,
            },
            'credentials': 'include'
        })

        const data = await result.json();

        return data;
    } catch (e) {
        return null;
    }
}

// ?q=paul`
// ?days=1`
// ?q=____` // road warrior
// ?q=paul 2022-01`
// ?ps=10`