/* eslint-disable */
import React, { Component } from "react";
import dashboardStyles from '../../DashboardStyling'

class BottomStatusBoxes extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <div className="row">
        <div className="col-3">

          <div>
            <div style={dashboardStyles.bottomBoxStatus1}>

            </div>
          </div>

        </div>

        <div className="col-6">
          <div style={dashboardStyles.bottomBoxStatus2}>

          </div>
        </div>

        <div className="col-3">
          <div style={dashboardStyles.bottomBoxStatus3}>

          </div>
        </div>
      </div>
    );
  }
}

export default BottomStatusBoxes
