/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import ReduxTest from '../../reduxtest'
import { AmplifySignOut } from '@aws-amplify/ui-react'
import Amplify, { Auth, Hub, API, graphqlOperation } from 'aws-amplify';
import profileStyles from './profileStying'
import symbolwhite from "../../symbolwhite.png";
import styles from '../../AuthStyling'

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      lastName: "",
      phoneNumber: "",
      birthday: "",
      pronoun: '',
      department: "",
      role: "",
      socialLinks: "",

      isAdmin: "",
      editModeOn: false,  //remember to set back as false
      errorMsg: false
    };
  }
  componentDidMount() {
    this.checkExistingAwsState()
  }

  checkExistingAwsState = () => {
    // check if data already exist. If so, populate via backends
    const { getCurrentUser } = this.props
    const user = getCurrentUser

    if (user) {
      if (user.name !== undefined ||
        user['custom:phoneNumber'] !== undefined
        // user['custom:lastName'] !== undefined || 
      ) {

        this.setState({
          name: user.name,
          phoneNumber: user['custom:phoneNumber']
          // lastName: user['custom:lastName'],
        })

      }
    }
  }

  editModeOnHandleChange = () => {
    const { name } = this.state;
    this.setState({
      name: name,
      editModeOn: !this.state.editModeOn,
    });
  };

  async signOut() {
    const user = await Auth.signOut()
  }

  cancelEditModeOnHandleChange = () => {
    this.checkExistingAwsState()
  };


  handleFirstNameChange = (event) => {
    this.setState({ name: event.target.value });
  };

  handleSecondNameChange = (event) => {
    this.setState({ lastName: event.target.value });
  };

  phoneNumberChange = (event) => {
    this.setState({ phoneNumber: event.target.value });
  };

  birthdayChange = (event) => {
    this.setState({ birthday: event.target.value });
  };

  pronounChange = (event) => {
    this.setState({ pronoun: event.target.value });
  };

  departmentChange = (event) => {
    this.setState({ department: event.target.value });
  };

  roleChange = (event) => {
    this.setState({ role: event.target.value });
  };

  socialLinkChange = (event) => {
    this.setState({ socialLinks: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { name, lastName, phoneNumber } = this.state;

    const UpdatedState = {
      name: name,
      lastName: lastName,
      phoneNumber: phoneNumber
    };

    if (name.length > 0 || lastName.length > 0 || phoneNumber.length > 0) {
      // update backend
      this.props.putRequest(UpdatedState);
      this.setState({
        // name: name,
        editModeOn: false,
        errorMsg: false,
      });
    } else {
      console.log('no updates made')
    }
  };

  render() {
    const { name, lastName, phoneNumber, birthday, pronoun, department, role, socialLinks, editModeOn, errorMsg } = this.state;
    const { getCurrentUser } = this.props
    return (
      <div style={profileStyles.profile_container_size}>
        <div>
          <div>
            <div >
              <div style={profileStyles.profile_picture_box}>
                <img
                  width='30%'
                  src={symbolwhite}
                  alt="newLogo"
                />
              </div>

            </div>
          </div>

          <div style={profileStyles.centerEverything}>
            <div >
              {getCurrentUser
                ? getCurrentUser.name !== undefined ? getCurrentUser.name : 'John/Jane'
                : console.log('user data doesnt exist')
              }
            </div>
          </div>
          <div style={profileStyles.centerEverything}>
            <div>
              {`${getCurrentUser.email}`}
            </div>
          </div>

          <br />

          <div >
            <form onSubmit={this.handleSubmit} style={profileStyles.profile_box_color}>
              {/* first row input */}
              <div className="form-row" style={profileStyles.centerEverything}>
                <div className="col-md-4 mb-3" >
                  <div style={profileStyles.text_box_shape_right}>
                    <label >Name</label>
                    <input style={profileStyles.text_box_border_style} disabled={editModeOn ? false : true} onChange={this.handleFirstNameChange} type="text" className="form-control" placeholder="John/Jane" value={name ? name : ""} required></input>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  {/* <div style={profileStyles.text_box_shape_left}>
                    <label >Last name</label>
                    <input style={profileStyles.text_box_border_style} disabled={editModeOn ? false : true} onChange={this.handleSecondNameChange} type="text" className="form-control" placeholder="Doe" value={lastName ? lastName : ""} ></input>
                  </div> */}
                </div>

              </div>
              {/* second row input */}
              <div className="form-row" style={profileStyles.centerEverything}>
                <div className="col-md-4 mb-3">
                  <div style={profileStyles.text_box_shape_right}>
                    <label >Phone number</label>
                    <input style={profileStyles.text_box_border_style} disabled={editModeOn ? false : true} onChange={this.phoneNumberChange} type="phone" className="form-control" placeholder='(555) 555-555' value={phoneNumber ? phoneNumber : ''} ></input>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div style={profileStyles.text_box_shape_left}>
                    <label >Birthday</label>
                    <input style={profileStyles.text_box_border_style} disabled={editModeOn ? false : true} onChange={this.birthdayChange} type="text" className="form-control" placeholder="04/13/1988" value={birthday ? birthday : ''} ></input>
                  </div>

                </div>
              </div>
              {/* third row input */}
              <div className="form-row" style={profileStyles.centerEverything}>
                <div className="col-md-4 mb-3">
                  <div style={profileStyles.text_box_shape_right}>
                    <label >Pronoun</label>
                    <input style={profileStyles.text_box_border_style} disabled={editModeOn ? false : true} onChange={this.pronounChange} type="text" className="form-control" placeholder="she/he" value={pronoun ? pronoun : ''} ></input>
                  </div>

                </div>
                <div className="col-md-4 mb-3">
                  <div style={profileStyles.text_box_shape_left}>
                    {/* placeholder*/}
                  </div>
                </div>
              </div>
              {/* fourth row input */}
              <div className="form-row" style={profileStyles.centerEverything}>
                <div className="col-md-4 mb-3">
                  <div style={profileStyles.text_box_shape_right}>
                    <label >Department</label>
                    <input style={profileStyles.text_box_border_style} disabled={editModeOn ? false : true} onChange={this.departmentChange} type="text" className="form-control" placeholder="tech" value={department ? department : ''} ></input>
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <div style={profileStyles.text_box_shape_left}>
                    <label >Role</label>
                    <input style={profileStyles.text_box_border_style} disabled={editModeOn ? false : true} onChange={this.roleChange} type="text" className="form-control" placeholder="Manager" value={role ? role : ''} ></input>
                  </div>
                </div>
              </div>
              {/* fifth row input */}
              <br />
              <div className="form-row" style={profileStyles.centerEverything}>
                <div className="col-md-8 mb-6">
                  <label >Social links</label>
                  <input style={profileStyles.text_box_border_style} disabled={editModeOn ? false : true} onChange={this.socialLinkChange} type="text" className="form-control" placeholder="LinkedIn" value={socialLinks ? socialLinks : ''} ></input>

                </div>
              </div>
              {/* sixth of rows  */}
              <div className="form-row" style={profileStyles.centerEverything}>
                <div className="col-md-8 mb-6">
                  <input style={profileStyles.text_box_border_style} disabled={editModeOn ? false : true} onChange={this.socialLinkChange} type="text" className="form-control" placeholder="Tweeter" value={socialLinks ? socialLinks : ''} ></input>
                </div>
              </div>
              {/* end of rows  */}
              <br />
              <div style={profileStyles.centerEverything}>
                {!editModeOn
                  ? <button onClick={this.editModeOnHandleChange}>EDIT</button>
                  : <div>
                    <div style={profileStyles.centerEverything}>
                      <button disabled={editModeOn ? false : true} type="submit">Save Changes</button>
                    </div>

                    <br />

                    <div style={profileStyles.centerEverything}>
                      <button onClick={this.cancelEditModeOnHandleChange}>Cancel</button>
                    </div>
                  </div>
                }
              </div>
            </form>

          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    updatedData: state.updateCurrentAwsUser,
    getCurrentUser: state.getCurrentUser.userData,
  };
};


export default connect(mapStateToProps)(Profile);
