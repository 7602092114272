/* eslint-disable */
import React, { Component } from "react";
import tableStyling from '../table_styling/tableStyling'
import months from '../helpers/api_services/months'

const columnWidth = {
    timeColumn: {
        width: '7%',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
    },
    addressColumn: {
        width: '7%',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
    },
    columnWidthFive: {
        width: '5%'
    },
    pickupDayColumn: {
        width: '2%'
    },
    pickupFreqColumn: {
        width: '3%'
    },
    nameColumn: {
        fontWeight: 'bold',
        width: '1%',
        textDecoration: 'underline'
    }
}

class CustomerTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            todaysDate: '',
        };
    }
    async componentDidMount() {
        const { customersArr } = this.props

        let datetime = new Date().toISOString()
        let year = datetime.substring(0, 10).split('-')[0]
        let month = datetime.substring(0, 10).split('-')[1]
        let day = datetime.substring(0, 10).split('-')[2]

        this.setState({
            todaysDate: `${month}/${day}/${year}`
        })
    }

    render() {
        const { customersArrLength, isPaginating, lastElementRef, loadMoreItems, customersArr, searchTerm, fetchSingleCustomer, prevOrCurrentToggle, setCurrentHandle, setPrevHandle, setAllHandle } = this.props
        const { todaysDate } = this.state

        let noDataShown = false

        if (customersArrLength === 0) {
            noDataShown = true
        }

        return (
            <div> {customersArrLength === 0
                ? 'No Data'
                : <div>
                    <div style={{ padding: '.2%' }}></div>

                    <table className={customersArr.length > 0 ? "table table-striped table-hover btn text-left" : ''}
                        style={tableStyling.tableBodyCustomer}>
                        <thead>
                            {customersArr.length > 0
                                ? <tr>
                                    <th scope="col" style={tableStyling.tableHeaderStyle}>Customer name</th>
                                    <th scope="col" style={tableStyling.tableHeaderStyle}>Address</th>
                                    <th scope="col" style={tableStyling.tableHeaderStyle}>Created date</th>
                                    <th scope="col" style={tableStyling.tableHeaderStyle}>Pick up day</th>
                                    <th scope="col" style={tableStyling.tableHeaderStyle}>Pickup freqency</th>
                                    <th scope="col" style={tableStyling.tableHeaderStyle}>Status</th>
                                </tr>
                                : <tr >
                                    <th scope="col"></th>
                                </tr>
                            }
                        </thead>
                        <tbody>
                            {
                                customersArr.length > 0 ?
                                    customersArr.map((data, i) => {
                                        // https://stackabuse.com/compare-two-dates-in-javascript/
                                        let datetime = data.created_date

                                        let currentTimeZone = new Date(datetime).toString()

                                        let monthIntoNum = ''
                                        let convertMilitaryTime = ''
                                        let currentTimeZoneShortened = ''

                                        if (datetime) {
                                            for (let key in months) {
                                                if (key === currentTimeZone.split(' ')[1]) {
                                                    monthIntoNum = months[key]
                                                }
                                            }
                                            convertMilitaryTime = moment(currentTimeZone.split(' ')[4], 'HH:mm:ss').format('h:mm:ss A')
                                            currentTimeZoneShortened = `${monthIntoNum}-${currentTimeZone.split(' ')[2]}-${currentTimeZone.split(' ')[3]} ${convertMilitaryTime}`
                                        }

                                        return (
                                            <tr key={i} onClick={() => fetchSingleCustomer(data.subscription_id)} >
                                                <td style={data.firstname === undefined || !data.lastname === undefined ? { color: 'black' } : columnWidth.nameColumn}>{data.firstname === undefined || !data.lastname === undefined ? '-' : `${data.firstname} ${data.lastname}`}</td>
                                                <td style={columnWidth.addressColumn}>{data.full_address === undefined ? '-' : `${data.full_address}`}</td>
                                                <td style={columnWidth.timeColumn}>{currentTimeZoneShortened ? `${currentTimeZoneShortened} (Local)` : ''}</td>
                                                <td style={columnWidth.pickupDayColumn}>{data.pickup_day}</td>
                                                <td style={columnWidth.pickupFreqColumn}>{data.pickup_freq}</td>
                                                <td style={columnWidth.columnWidthFive}>
                                                    <span className="fas fa-circle" style={data.record_status === 'active' ? { color: 'green' } : { color: 'red' }}>{' '}</span>
                                                    {` ${data.record_status}`}
                                                </td>
                                            </tr>
                                        )
                                    })
                                    : <tr  >
                                        <td >{`No Data`}</td>
                                    </tr>}
                        </tbody>
                    </table>
                    <div style={{ paddingLeft: '2%' }} ref={prevOrCurrentToggle === 'showCurrent' || searchTerm.length !== 0 ? '' : lastElementRef}>
                        {searchTerm.length === 0 && prevOrCurrentToggle !== 'showCurrent'
                            ? noDataShown ? '' : 'loading...'
                            : ''}
                    </div>
                </div>
            }
            </div>
        );
    }
}

export default CustomerTable
