const tableStyling = {
    centerEverything: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    pickUpContainer: {
        paddingLeft: '3%',
        paddingTop: '2%',
        paddingRight: '2.3%'
    },
    customerContainer: {
        paddingLeft: '.8%',
        paddingTop: '2%',
        paddingRight: '2%'
    },
    pageTitleSize: {
        fontSize: '2.5vh'
    },
    closePanelxButtonSingleCustomer: {
        fontSize: '1.6vh', fontWeight: 'bold', pointerEvents: 'fill', color: 'white'
    },
    panelCardTopPickup: {
        height: '39vh',
        paddingTop: '2%',
        paddingLeft: '2%',
        borderRadius: "20px",
        backgroundColor: 'white'
    },
    panelCardBottomPickup: {
        height: '40.5vh',
        paddingTop: '2%',
        paddingLeft: '2%',
        borderRadius: "20px",
        backgroundColor: 'white'
    },
    greyBackgroundColor: {
        backgroundColor: '#f4f6f9'
    },
    mainCardDisplaySingleCustomer: {
        backgroundColor: 'white',
        borderRadius: "20px",
    },
    mainCardTitleFontSingleCustomer: {
        fontSize: '2.5vh', fontWeight: 'bold', paddingTop: '1%',
    },
    mainCardFontSingleCustomer: {
        fontSize: '1.5vh'
    },
    mainCardInfoSectionSingleCustomer: {
        background: '#F4F4F4', padding: '2%'
    },
    tableSizeSingleCustomer: {
        width: 980,
        fontSize: '1.5vh',
    },
    topPanelSingleCustomer: {
        height: '39vh',
        backgroundColor: 'white',
        borderRadius: "20px",
        padding: '1%'
    },
    bottomPanelSingleCustomer: {
        height: '39.5vh',
        backgroundColor: 'white',
        borderRadius: "20px",
        padding: '1%'
    },

    tableBodyPickup: {
        fontSize: '1.4vh',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
    },
    tableBodyCustomer: {
        fontSize: '1.4vh',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
    },
    tableHeaderStyle: {
        background: 'white', position: 'sticky', top: '0', zIndex: '10',
        borderBottom: '1px solid black',
    },
    pageTitleFont: {
        paddingTop: '2%',
        paddingLeft: '1%',
        fontWeight: 'bold',
    },

    clickedHistoryTab: { border: '.5px solid #FCC816', borderRadius: "20px 0px 0px 0px", backgroundColor: '#FCC816', padding: '1%', fontWeight: 'bold', color: 'white' },
    notClickedHistoryTab: { border: '.5px solid #BBBBBB', borderRadius: "20px 0px 0px 0px", padding: '1%', fontWeight: 'bold', color: 'black' },

    clickedCurrentTab: { border: '.5px solid #FCC816', backgroundColor: '#FCC816', padding: '1%', fontWeight: 'bold', color: 'white' },
    notClickedCurrentTab: { border: '.5px solid #BBBBBB', padding: '1%', fontWeight: 'bold', color: 'black' },

    clickedAllTab: { border: '.5px solid #FCC816', borderRadius: "0px 20px 0px 0px", backgroundColor: '#FCC816', padding: '1%', fontWeight: 'bold', color: 'white' },
    notClickedAllTab: { border: '.5px solid #BBBBBB', borderRadius: "0px 20px 0px 0px", padding: '1%', fontWeight: 'bold', color: 'black' },

    clickedHistoryTabPickup: { border: '.5px solid #FCC816', borderRadius: "20px 0px 0px 0px", backgroundColor: '#FCC816', padding: '1%', fontWeight: 'bold', color: 'white', marginLeft: '.5%' },
    notClickedHistoryTabPickup: { border: '.5px solid #BBBBBB', borderRadius: "20px 0px 0px 0px", padding: '1%', fontWeight: 'bold', color: 'black', marginLeft: '.5%' },

    clickedAllTabPickup: { border: '.5px solid #FCC816', borderRadius: "0px 20px 0px 0px", backgroundColor: '#FCC816', padding: '1%', fontWeight: 'bold', color: 'white', marginRight: '.5%' },
    notClickedAllTabPickup: { border: '.5px solid #BBBBBB', borderRadius: "0px 20px 0px 0px", padding: '1%', fontWeight: 'bold', color: 'black', marginRight: '.5%' },


    headerTabStyling: {
        fontSize: '1.6vh',
    },

    tableColumnWrapper: { maxWidth: 100, whiteSpace: 'normal', wordWrap: 'break-word' },

    pagePadding: {
        // paddingTop: "1%",
        // border: 'solid blue'
    },
    cardPadding: {
        padding: '2%  1% 2% 1%'
    },
    cardContainer: {
        width: '150vh',
    },
    cardStyling: {
        backgroundColor: 'white',
        borderRadius: "20px",
    },
    searchBarStyling: {
        borderRadius: "20px", border: '2px solid #BBBBBB', backgroundColor: 'white'
    },
    searchInput: {
        width: '100%', border: 'none', borderRadius: "20px"
    },
    searchIcon: {
        height: '18px',
        width: '18px',
        cursor: "pointer", color: '#666666'
    },
    downloadIcon: {
        display: 'flex',
        height: '25px',
        width: '25px',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2%',
        cursor: "pointer", color: '#666666',
    },
    isLoadingSpinner: {
        height: '60vh',
        display: 'flex', justifyContent: 'center', alignItems: 'center'
    },
    isLoadingSpinnerSingleCustomer: {
        height: '40vh',
        display: 'flex', justifyContent: 'center', alignItems: 'center'
    },
    scrollBarStylingPickupTable: {
        height: '71.5vh',
        overflowY: 'scroll'
    },
    scrollBarStylingCustomerTable: {
        height: '71.5vh',
        overflowY: 'scroll'
    },
    scrollBarSingleCustomerTableRowClicked: {
        height: '52vh',
        overflowY: 'scroll'
    },
    scrollBarSingleCustomerTable: {
        height: '40vh',
        overflowY: 'scroll'
    },
    imagePopOut: {
        padding: '4%',
        height: '50%',
        border: '2px solid green'
    },
    imagePopOutSizing: {
        height: '50%',
        padding: '2%',
        borderRadius: "20px",
        backgroundColor: 'white'
    },
    customerDataStyling: {
        width: '50%',
        background: '#F4F4F4',
    },
    customerDataDivider: {
        padding: '.3%'
    },
    customerSingleCard: {
        padding: '1.5%',
    },
    customerCardWhiteSpace: {
        background: 'white', padding: '.5%'
    },
    pickUpImageSize: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '250px',
        width: '250px',
    },
    pickUpNoMapSize: {
        width: '80%',
        height: '80%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2%',
    },
    pickUpNoMapSizeSingleCustomer: {
        height: '363px',
    },
    pickUpMapSize: {
        height: '100%',
        border: 'solid blue',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    pickupTablePadding: {
        paddingLeft: '1%',
        paddingTop: '2%',
        paddingRight: '2%',
    },
    closeButton: {
        fontSize: '1.6vh', fontWeight: 'bold', pointerEvents: 'fill'
    },
    textImage: {
        fontSize: '1.6vh', fontWeight: 'bold'
    },
    ClickToEnlargeOrNoImageMsgOrMaps: {
        fontWeight: 'bold', fontSize: '1.6vh',
    },
    iconColor: {
        color: '#666666'
    },
    red: {
        color: 'red'
    },
    blue: {
        color: 'blue'
    }
};

export default tableStyling