/* eslint-disable */
import React, { Component } from "react";
import NotAnEmployee from '../non-employee/errorPage'
import Layout from '../layout/layout'
import { checkIfAuthUser } from '../helpers/api_services/checkIfAuthUser'

class Gateway extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDyperEmployee: false,
            isLoading: true
        };
    }

    async componentDidMount() {
        const { isEmployee, checkUser } = this.props

        let isAuthUser = await checkIfAuthUser()

        if (isAuthUser) {
            this.setState({
                isDyperEmployee: true,
                isLoading: false
            })
        }
    }

    render() {
        const { isDyperEmployee } = this.state
        const { checkUser } = this.props

        return (
            <div>
                <div>
                    {
                        isDyperEmployee
                            ? <div>
                                <Layout checkUser={checkUser} />
                            </div>
                            : <NotAnEmployee checkUser={checkUser} />
                    }
                </div>
            </div>
        );
    }
}

export default Gateway
