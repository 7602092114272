/* eslint-disable */
import React, { Component } from "react";
import { fetchPickUpSingleCustomer } from '../helpers/api_services/fetchPickUpSingleCustomer'
import months from '../helpers/api_services/months'
import moment from 'moment'
import tableStyling from '../table_styling/tableStyling'
import MapPinDisplay from '../google_maps/mapPinDisplay'
import dyperlogo from '../../dyperlogo.png'
import { BallTriangle } from 'react-loader-spinner'

const componentStyling = {
    imageTextDisplayTop: {
        fontSize: '1.6vh', fontWeight: 'bold', paddingLeft: '5%', paddingTop: '2%'
    },
    imageTextDisplayBottom: {
        fontWeight: 'bold', fontSize: '1.6vh',
    },
    mapsTextDisplayTop: {
        fontSize: '1.6vh', fontWeight: 'bold', paddingLeft: '5%', paddingTop: '2%', paddingBottom: '1%'
    },
    tableTimeColumnWidth: {
        width: '20%'
    },
    tableImageColumnWidth: {
        width: '20%', fontSize: '1.6vh',
    },
    tableNotesColumnWidth: {
        width: '20%', whiteSpace: 'normal', wordWrap: 'break-word'
    },
    tableColumnTopColor: {
        backgroundColor: '#F4F4F4'
    },
    recordStatusRed: {
        color: 'red'
    },
    recordStatusGreen: {
        color: 'green'
    }
}

class SingleCustomerData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customerBadPickupData: [],
            fullName: '',
            address: '',
            phoneNumber: '',
            subscriptionType: '',
            pickupFreq: '',
            pickupDay: '',
            noCustomerData: false,
            todaysDate: '',
            rowClicked: false, //
            customerLatLng: {
                lat: 33.812511,
                lng: -117.918976
            },
            pickupImage: '',
            isLoading: true,
            recordStatus: false
        };
    }
    async componentDidMount() {
        const { selectedCustomer, getCurrentUser } = this.props

        let datetime = new Date().toISOString()
        let year = datetime.substring(0, 10).split('-')[0]
        let month = datetime.substring(0, 10).split('-')[1]
        let day = datetime.substring(0, 10).split('-')[2]

        const token = getCurrentUser

        const BadPickUpSingleCustomer = await fetchPickUpSingleCustomer(selectedCustomer.subscription_id, token)

        let customerPickupData = []

        if (BadPickUpSingleCustomer) {
            if (BadPickUpSingleCustomer.Reports) {
                customerPickupData = [...BadPickUpSingleCustomer.Reports]
                this.setState({
                    isLoading: false
                })
            }
        } else {
            this.setState({
                isLoading: true
            })
        }

        if (selectedCustomer) {
            if (customerPickupData.length <= 0) {
                this.setState({
                    noCustomerData: true
                })
            } else {
                this.setState({
                    noCustomerData: false
                })
            }

            if (selectedCustomer.record_status === 'active') {
                this.setState({
                    recordStatus: true
                })
            }

            this.setState({
                customerBadPickupData: customerPickupData,
                fullName: `${selectedCustomer.firstname} ${selectedCustomer.lastname}`,
                address: `${selectedCustomer.address_1} ${selectedCustomer.state_region} ${selectedCustomer.postal_code}`,
                subscriptionType: selectedCustomer.subscription_type,
                pickupFreq: selectedCustomer.pickup_freq,
                pickupDay: selectedCustomer.pickup_day,
                todaysDate: `${month}/${day}/${year}`,
            })
        }
    }

    rowClickedTrueHandle = (selectedPickup) => {
        const { rowClicked, customerLatLng } = this.state

        this.setState({
            customerLatLng: {
                lat: 33.812511,
                lng: -117.918976
            },
            pickupImage: ''
        })

        if (selectedPickup.latitude && selectedPickup.longitude) {
            this.setState({
                customerLatLng: {
                    lat: parseFloat(selectedPickup.latitude),
                    lng: parseFloat(selectedPickup.longitude)
                },
                pickupImage: selectedPickup.photo_link
            })
        }

        this.setState({
            rowClicked: true
        })
    }

    hideImageMapHandle = () => {
        this.setState({
            rowClicked: false
        })
    }

    async componentWillUnmount() {
        this.setState({
            customerBadPickupData: [],
            fullName: ``,
            address: ``,
            subscriptionType: '',
            pickupFreq: '',
            pickupDay: '',
            todaysDate: ``,
            pickupImage: '',
            customerLatLng: {
                lat: 33.812511,
                lng: -117.918976
            },
        })
    }

    render() {
        const { recordStatus, isLoading, pickupImage, rowClicked, fullName, address, customerBadPickupData, pickupFreq, noCustomerData, pickupDay, customerLatLng } = this.state
        const { toggleBack } = this.props

        return (
            <div>
                {/* close image and map panel - start */}
                <div className="row" style={tableStyling.greyBackgroundColor}>
                    <div className="col-12 text-right">
                        {rowClicked
                            ? <div className='fas fa-times' style={tableStyling.closeButton} onClick={() => this.hideImageMapHandle()}></div>
                            : <div style={tableStyling.closePanelxButtonSingleCustomer}>x</div>}
                    </div>
                </div>
                {/* close image and map panel- end */}

                <div className="row">
                    {/* when row is clicked, image and map will appear - col-8  */}
                    <div className={rowClicked ? "col-8" : "col-12"} style={tableStyling.greyBackgroundColor} >
                        <div className="" style={tableStyling.mainCardDisplaySingleCustomer}>
                            <div onClick={() => toggleBack()} >
                                <div style={tableStyling.mainCardTitleFontSingleCustomer}>
                                    <span className='btn'>{`< `}</span>
                                    <span>{`Customer Management`}</span>
                                </div>
                            </div>

                            <div style={{ paddingBottom: '1%' }}>
                                <br />

                                <div className="" >
                                    <div className="col-12" style={tableStyling.customerSingleCard} >
                                        <div className="row" >
                                            <div className="col" style={tableStyling.mainCardInfoSectionSingleCustomer}>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div style={tableStyling.customerDataDivider}></div>
                                                        <div>
                                                            <div className="row text-left" style={tableStyling.customerDataStyling}>
                                                                <div className="col" style={tableStyling.mainCardFontSingleCustomer}>
                                                                    <div style={{ fontWeight: 'bold' }}>
                                                                        {fullName ? `${fullName} ` : '-'}
                                                                    </div>
                                                                </div>
                                                                <div className="col" style={tableStyling.mainCardFontSingleCustomer}>
                                                                    <span className="fas fa-circle" style={recordStatus ? componentStyling.recordStatusGreen : componentStyling.recordStatusRed}>{recordStatus ? ' Active' : ' Canceled'}</span>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <div style={tableStyling.customerDataDivider}></div>
                                                        <div>
                                                            <div className="row text-left" style={tableStyling.customerDataStyling}>
                                                                <div className="col" style={tableStyling.mainCardFontSingleCustomer}>
                                                                    Address
                                                                </div>
                                                                <div className="col" style={tableStyling.mainCardFontSingleCustomer}>
                                                                    <div>{address ? address : '-'}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div style={tableStyling.customerDataDivider}></div>
                                                        <div>
                                                            <div className="row text-left" style={tableStyling.customerDataStyling}>
                                                                <div className="col" style={tableStyling.mainCardFontSingleCustomer}>
                                                                    Pick up frequency
                                                                </div>
                                                                <div className="col" style={tableStyling.mainCardFontSingleCustomer}>
                                                                    <div>{pickupFreq ? pickupFreq : '-'}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div style={tableStyling.customerDataDivider}></div>
                                                        <div>
                                                            <div className="row text-left" style={tableStyling.customerDataStyling}>
                                                                <div className="col" style={tableStyling.mainCardFontSingleCustomer}>
                                                                    Pick up day
                                                                </div>
                                                                <div className="col" style={tableStyling.mainCardFontSingleCustomer}>
                                                                    <div>{pickupDay ? pickupDay : '-'}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {rowClicked ? ''
                                                : <div className={"col-4"}></div>
                                            }

                                        </div>
                                    </div>

                                    <div style={tableStyling.customerCardWhiteSpace}> </div>

                                    <div style={rowClicked ? tableStyling.scrollBarSingleCustomerTableRowClicked : tableStyling.scrollBarSingleCustomerTable}>

                                        {!isLoading ?
                                            <table className={noCustomerData ? '' : "table table-hover btn text-left"}
                                                style={tableStyling.tableSizeSingleCustomer} >
                                                <thead>
                                                    {noCustomerData
                                                        ? <tr>
                                                            <th scope="col">{''}</th>
                                                        </tr>
                                                        : <tr style={componentStyling.tableColumnTopColor}>
                                                            <th scope="col">Pickup Date</th>
                                                            <th scope="col">Photo</th>
                                                            <th scope="col">Notes</th>
                                                            <th scope="col"></th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    }
                                                </thead>
                                                {noCustomerData ?
                                                    <tbody>
                                                        <tr>
                                                            <td>{'No Data'}</td>
                                                        </tr>
                                                    </tbody>
                                                    : customerBadPickupData.length > 0
                                                        ? customerBadPickupData.map((data, i) => {

                                                            let datetime

                                                            if (data.checkin_timestamp) {
                                                                datetime = data.checkin_timestamp
                                                            } else {
                                                                datetime = data.issue_timestamp
                                                            }

                                                            let currentTimeZone = new Date(datetime).toString()

                                                            let monthIntoNum = ''
                                                            let convertMilitaryTime = ''
                                                            let currentTimeZoneShortened = ''

                                                            if (datetime) {
                                                                for (let key in months) {
                                                                    if (key === currentTimeZone.split(' ')[1]) {
                                                                        monthIntoNum = months[key]
                                                                    }
                                                                }
                                                                convertMilitaryTime = moment(currentTimeZone.split(' ')[4], 'HH:mm:ss').format('h:mm:ss A')
                                                                currentTimeZoneShortened = `${monthIntoNum}-${currentTimeZone.split(' ')[2]}-${currentTimeZone.split(' ')[3]}-${convertMilitaryTime}`
                                                            }

                                                            return (
                                                                <tbody key={i} onClick={() => this.rowClickedTrueHandle(data)}>
                                                                    <tr>
                                                                        <td style={componentStyling.tableTimeColumnWidth}>{currentTimeZoneShortened ? `${currentTimeZoneShortened} (Local)` : ''}</td>

                                                                        <td style={componentStyling.tableImageColumnWidth}>
                                                                            {data.photo_link.length > 0 ? <div className="far fa-image center" ></div> : 'No Image'}

                                                                        </td>
                                                                        <td style={componentStyling.tableNotesColumnWidth}>{data.notes.length > 0 ? data.notes : '-'}</td>
                                                                        <td style={tableStyling.tableColumnWrapper}>{''}</td>
                                                                        <td style={tableStyling.tableColumnWrapper}>{''}</td>
                                                                    </tr>
                                                                </tbody>
                                                            )
                                                        })
                                                        : <tbody >
                                                            <tr>
                                                                <td></td>
                                                            </tr>
                                                        </tbody>
                                                }

                                            </table>
                                            : <div style={tableStyling.isLoadingSpinnerSingleCustomer}>
                                                <BallTriangle
                                                    heigth="100"
                                                    width="100"
                                                    color="#FCC816"
                                                    ariaLabel="loading-indicator"
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div>

                    {/* if row is clicked, image and maps will appear */}
                    {rowClicked
                        ? <div className="col" style={tableStyling.greyBackgroundColor}>

                            <div className="row" style={{ paddingLeft: '2%' }}>
                                <div className="col-12"
                                    style={tableStyling.topPanelSingleCustomer}>
                                    <div style={componentStyling.imageTextDisplayTop} >Image</div>
                                    <div className="col-12" style={{ padding: '1%' }}> </div>
                                    <div className='container' style={tableStyling.centerEverything}>
                                        <div className="panelContent" >
                                            <div className="row panelRow">
                                                <div className="panelCell">
                                                    {pickupImage
                                                        ? <a href={pickupImage} style={{ display: "table-cell" }} target="_blank">
                                                            <img
                                                                style={tableStyling.pickUpImageSize}
                                                                src={pickupImage}
                                                                alt="pickupImage" />
                                                            <div className='col-12 text-center' style={componentStyling.imageTextDisplayBottom}>
                                                                {pickupImage.length > 0 ? 'Click to enlarge' : 'No Image'}</div>
                                                        </a>
                                                        : <div>
                                                            <img
                                                                style={tableStyling.pickUpImageSize}
                                                                src={dyperlogo}
                                                            />
                                                            <div className='col-12 text-center' style={tableStyling.ClickToEnlargeOrNoImageMsgOrMaps}>
                                                                {customerLatLng.lat === 33.812511 && customerLatLng.lng === -117.918976
                                                                    ? 'No Image'
                                                                    : <div>{``}</div>
                                                                }</div></div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12" style={{ padding: '1%' }}> </div>

                                <div className="col-12" style={tableStyling.bottomPanelSingleCustomer} >

                                    <div style={componentStyling.mapsTextDisplayTop} >Maps</div>
                                    <div className="row">
                                        <div className="col-12" style={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}>
                                            {/*  */}
                                            <div className='container' style={tableStyling.centerEverything}>
                                                <div className="panelContent" >
                                                    <div className="row panelRow">
                                                        <div className="panelCell">
                                                            {customerLatLng.lat === 33.812511 && customerLatLng.lng === -117.918976
                                                                ? <div>
                                                                    <img
                                                                        style={tableStyling.pickUpImageSize}
                                                                        src={dyperlogo}
                                                                    />
                                                                    <div className='col-12 text-center'
                                                                        style={tableStyling.ClickToEnlargeOrNoImageMsgOrMaps}>
                                                                        {customerLatLng.lat === 33.812511 && customerLatLng.lng === -117.918976
                                                                            ? 'No Map'
                                                                            : <div>{``}</div>
                                                                        }</div>
                                                                </div>
                                                                : <div className="container" >
                                                                    <MapPinDisplay
                                                                        customerLatLng={customerLatLng}
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        : ''}
                </div>


            </div >
        );
    }
}

export default SingleCustomerData
