/* eslint-disable */
import { Auth } from 'aws-amplify';

export async function checkIfAuthUser() {
    try {
        return Auth.currentAuthenticatedUser()
            .then((user) => {
                let groupArr = user.signInUserSession.accessToken.payload[process.env.REACT_APP_GROUP]

                let isAuthUser = false

                if (groupArr) {
                    for (let i = 0; i < groupArr.length; i++) {
                        if (groupArr[i] === process.env.REACT_APP_EMPLOYEE_GROUP || groupArr[i] === process.env.REACT_APP_DYPER_EMPLOYEE_GROUP) {
                            isAuthUser = true
                            return isAuthUser
                        }
                    }
                }

            })
            .catch((err) => console.log('error with login', err));
    } catch (e) {
        return null;
    }
}
