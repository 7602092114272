/* eslint-disable */
import React, { Component } from "react";


class CompanyStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    render() {
        return (
            <div className=''>Calendar</div>
        );
    }
}

export default CompanyStatus
