/* eslint-disable */
import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Dashboard from "../dashboard/dashboard";
import Menu from "../menu/menu";

import ProfileLayout from '../profile/profileLayout'
import Settings from '../settings/settings'
import PickupData from '../pickup_data/PickupData'
import CustomerData from '../customer_data/customerData'
import Logout from "../logout/logout";

import dashboardStyles from '../dashboard/DashboardStyling'
import styles from '../../AuthStyling'

import NotAnEmployee from '../non-employee/errorPage'
import { checkIfAuthUser } from '../helpers/api_services/checkIfAuthUser'

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileClicked: true,
      isDyperEmployee: false,
      isLoading: true
    };
  }

  async componentDidMount() {
    const { isDyperEmployee, isLoading } = this.state

    let isAuthUser = await checkIfAuthUser()

    setTimeout(() => {
      this.setState({
        isLoading: false
      })

      if (isAuthUser) {
        this.setState({
          isDyperEmployee: true,
        })
      }


    }, 1000);


  }

  render() {

    const { isDyperEmployee, isLoading } = this.state
    const { checkUser } = this.props

    return (
      <div className="wrapper" style={dashboardStyles.appBody}>
        {isDyperEmployee
          ? <Router>
            <section className="section ">
              <Menu />
              <div className="" style={dashboardStyles.dashboard_background_color}>
                <Switch>
                  <Route exact path="/" component={PickupData} />
                  <Route exact path="/pickupdata" component={PickupData} />
                  <Route exact path="/customerdata" component={CustomerData} />
                  <Route exact path="/logout" component={Logout} />
                  {/* <Route exact path="/index.html" component={Dashboard} /> */}
                  {/* <Route exact path="/" component={Dashboard} /> */}
                  {/* <Route exact path="/profile" component={ProfileLayout} /> */}
                  {/* <Route exact path="/settings" component={Settings} /> */}
                </Switch>
              </div>
            </section>
          </Router>
          : <div>
            {isLoading
              ?
              <div style={styles.welcomePageLayoutRight}>
                <div className="row text-center">
                  <div className="col-12">
                    <div>
                      {`Loading...`}
                    </div>
                    <br />
                  </div>
                </div>
              </div>
              : <NotAnEmployee checkUser={checkUser} />}
          </div>

        }
      </div>
    );
  }
}


export default Layout
//harris+dypertester@recyclops.com
//!T35tPas5W0rd!