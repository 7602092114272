export async function fetchNextCustomers(token, nextLink) {
    try {
        let result = await fetch(`${nextLink}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': `Bearer ${token}`,
            },
            'credentials': 'include'
        });

        const data = await result.json();

        return data;
    } catch (e) {
        return null;
    }
}

// &ps=5&?
// ?ps=10`
