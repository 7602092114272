const dashboardStyles = {
  appBody: {
    // height: '98vh',
    backgroundColor: '#f4f6f9'
  },
  borderVisual: {
    border: '3px solid green',
    height: '100%',
    width: '100%'
  },
  dashboard_theme: {
    background: '#FCC816'
  },
  dashboard_padding_Left: {
    padding: '2.5% 1% 5% 1%'
  },
  dashboard_background_color: {
    backgroundColor: "#F5F5F5",
  },
  greeting_font: {
    color: "#000000",
    fontWeight: "bold",
    paddingTop: "1%",
    paddingLeft: "1%",
  },
  greeting_sizing: {
    fontSize: "1.9vw",
  },
  date_styling: {
    fontSize: "100%",
    color: "#838383",
  },
  graphLayout: {
    background: "rgba(245, 42, 42, 0.2)",
  },
  head_tiles_red: {
    width: "97%",
    background: "rgba(245, 42, 42, 0.2)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    paddingLeft: '5%',
    paddingTop: '3%',
    marginRight: "2%",
    marginLeft: "2%",
  },
  head_tiles_green: {
    width: "97%",
    background: "rgba(60, 180, 170, 0.3)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    paddingLeft: '5%',
    paddingTop: '3%',
    marginRight: "2%",
    marginLeft: "2%",
  },
  head_tiles_number: {
    fontSize: "2vw",
    fontWeight: "bold",
  },
  head_tiles_label: {
    fontSize: "1.3vw",
    fontWeight: "bold",
  },
  head_tiles_percent_status: {
    fontSize: ".9vw",
    padding: '5%',
    paddingBottom: '7%'
  },
  search_bar_styling: {
    background: "#FFFFFF",
    border: "1px solid #B8B8B8",
    boxSizing: "border-box",
    borderRadius: "20px",
  },
  search_bar_location: {
    paddingTop: "5%",
    paddingLeft: "35%",
  },
  statusTable: {
    height: "95%",
    background: 'linear-gradient(180deg, rgba(252, 200, 22, 0.2) 0%, rgba(252, 200, 22, 0) 100%)',
    borderRadius: '20px 20px 20px 20px',
    backgroundColor: "#FFFFFF",
    marginRight: "1%",
    marginLeft: "2%",
  },
  graphTable: {
    width: '100%',
    height: '95%',
    borderRadius: '20px 20px 20px 20px',
  },
  centerEverything: {
    display: 'flex',
    justifyContent: 'center'
  },
  bottomBoxStatus1: {
    paddingBottom: '30%', // if content is blank, this is padding to keep visibility
    width: "100%",
    height: "100%",
    background: 'linear-gradient(180deg, rgba(252, 200, 22, 0.2) 0%, rgba(252, 200, 22, 0) 98.96%)',
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    marginRight: "2%",
    marginLeft: "2%",
  },
  bottomBoxStatus2: {
    width: "99%",
    height: "100%",
    background: 'linear-gradient(180deg, rgba(252, 200, 22, 0.2) 0%, rgba(252, 200, 22, 0) 98.96%)',
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    marginRight: "2%",
    marginLeft: "2%",
  },
  bottomBoxStatus3: {
    width: "97%",
    height: "100%",
    background: 'linear-gradient(180deg, rgba(252, 200, 22, 0.2) 0%, rgba(252, 200, 22, 0) 98.96%)',
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    marginLeft: "6%",
  },
  BottomStatusBoxesContainer: {
    width: '99%'
  }
};

export default dashboardStyles