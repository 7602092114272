/* eslint-disable */
import { useState, useEffect } from 'react';
import { fetchOneCustomer } from '../helpers/api_services/fetchOneCustomer'
import { fetchPickups } from '../helpers/api_services/fetchPickups'
import { fetchSearchPickups } from '../helpers/api_services/fetchSearchPickups'
import { fetchNextPickups } from '../helpers/api_services/fetchNextPickups'
import { fetchPickupsCurrent } from '../helpers/api_services//fetchPickupsCurrent'
import { fetchPickupsHistory } from '../helpers/api_services/fetchPickupsHistory'

import MapPinDisplay from '../google_maps/mapPinDisplay'

import PickupsTable from './pickupsTable'

import { useHistory } from "react-router-dom";

import FileSaver from 'file-saver';

import { connect } from "react-redux";

import tableStyling from '../table_styling/tableStyling'

import dyperlogo from '../../dyperlogo.png'
import download from '../../download.png'
import search from '../../Search.png'

import months from '../helpers/api_services/months'
import moment from 'moment'

import { BallTriangle } from 'react-loader-spinner'

import useInfiniteScroll from "../helpers/api_services/useInfiniteScroll";

function PickupData({ customerSelected, getCurrentUser }) {

  const [searchTerm, setSearchTerm] = useState('');
  const [projectsArr, setProjectsArr] = useState([]);

  const [pickupImage, setPickupImage] = useState("");
  const [customerLatLng, setcustomerLatLng] = useState({});

  const [selectedCustomer, setselectedCustomer] = useState({});
  const [selectedProject, setSelectedProject] = useState({});
  const [projectSelectedBool, setProjectSelectedBool] = useState(false); //
  const [customerSelectedBool, setCustomerSelectedBool] = useState(false);
  const [searchBarClicked, setSearchBarClicked] = useState(false);

  const [downLoadData, setDownLoadData] = useState([]);

  const [prevOrCurrentToggle, setPrevOrCurrentToggle] = useState('showAll');

  const [token, setToken] = useState('');

  const [indexOfSelectedPickup, setIndexOfSelectedPickup] = useState('');

  const [selectedPickupBool, setSelectedPickupBool] = useState(false);

  const [isLoadingMainTable, setIsLoadingMainTable] = useState(true);
  const [isLoadingCustomerSelectedTable, setIsLoadingCustomerSelectedTable] = useState(false);

  const [nextLink, setNextLink] = useState('');
  const [nextLinkBool, setNextLinkBool] = useState(true);

  const [isFetching, setIsFetching] = useState(false);
  const [isPaginating, setIsPaginating] = useState(true);

  const [projectsArrLength, setprojectsArrLength] = useState(0);

  const [lastElementRef] = useInfiniteScroll(
    isPaginating ? loadMoreItems : () => { },
    isFetching
  );

  useEffect(() => {
    loadMoreItems();

    setPrevOrCurrentToggle('showAll')
    setIsFetching(false)
    setIsPaginating(true)

    setSearchBarClicked(false)
    setIndexOfSelectedPickup('')
    setSelectedPickupBool(false)
    setIsLoadingMainTable(true)
    setIsLoadingCustomerSelectedTable(false)

    setToken('')
    setPickupImage('')
    setSearchTerm('')
    setcustomerLatLng({})
    setselectedCustomer({})
    setSelectedProject({})
    setProjectSelectedBool(false) //
    setCustomerSelectedBool(false)
    setDownLoadData([])

    setProjectsArr([])
    fetchDyperPickups()

    setNextLink('')
    setNextLinkBool(true)
    setprojectsArrLength(0)

  }, [])

  async function fetchDyperPickups() {
    const token = getCurrentUser
    setProjectsArr([])
    setprojectsArrLength(0)
    setNextLink('')

    setToken(token)

    const fetchPickupsCall = await fetchPickups(token)

    if (fetchPickupsCall) {
      setIsLoadingMainTable(false)
      if (fetchPickupsCall.Reports) {
        setProjectsArr([...fetchPickupsCall.Reports])
        setprojectsArrLength(fetchPickupsCall.Reports.length)
      }
      if (fetchPickupsCall.Next) {
        setNextLink(fetchPickupsCall.Next)
      } else {
        setNextLink('')
      }
    }
  }

  async function loadMoreItems() {
    const token = getCurrentUser
    setIsFetching(true);

    let updatedProjectArray = []

    if (nextLink.length > 0) {
      let data = await fetchNextPickups(token, nextLink)

      if (data) {
        if (data.Reports.length > 0) {
          updatedProjectArray = [...projectsArr, ...data.Reports]
          setProjectsArr(updatedProjectArray)
        }
        if (data.Next) {
          setNextLink(data.Next)
          setIsPaginating(true)
        } else {
          setNextLink('')
          setNextLinkBool(false)
          setIsPaginating(false)
        }
        setIsFetching(false);
      }
    }
  }

  const history = useHistory();

  const fetchSingleCustomer = async (id) => {
    const token = getCurrentUser
    let customer = await fetchOneCustomer(id, token)

    setIsLoadingCustomerSelectedTable(true)
    setcustomerLatLng({})

    setSelectedPickupBool(false)
    setProjectSelectedBool(false)

    if (customer) {
      customerSelected(customer)
      setselectedCustomer(customer)
      setIsLoadingCustomerSelectedTable(false)

      setProjectsArr([])
      setCustomerSelectedBool(false)

      let path = `customerdata`;
      history.push(path);
    }
  }

  const setCurrentHandle = async () => {
    setPrevOrCurrentToggle('showCurrent')

    setSearchTerm('')
    setselectedCustomer({})
    setSelectedProject({})
    setProjectSelectedBool(false)
    setCustomerSelectedBool(false)

    setIsLoadingMainTable(true)

    const token = getCurrentUser

    let today = new Date();
    let yesterday = new Date(today);

    let dd = yesterday.getDate();
    let mm = 0
    let yyyy = yesterday.getFullYear();

    for (let key in months) {
      if (yesterday.toDateString().split(' ')[1] === key) {
        mm = months[key]
      }
    }

    let daysDate = yyyy + '-' + (mm) + '-' + (dd < 10 ? '0' + dd : dd)
    let fetchPickupsbyTodaysDate = await fetchPickupsCurrent(token, daysDate)

    if (fetchPickupsbyTodaysDate) {
      if (fetchPickupsbyTodaysDate.Reports) {
        setProjectsArr(fetchPickupsbyTodaysDate.Reports)
        if (Object.keys(fetchPickupsbyTodaysDate.Reports).length > 0) {
          setprojectsArrLength(1)
        }
        setIsLoadingMainTable(false)
      }
    }
  }

  const setPrevHandle = async () => {
    setPrevOrCurrentToggle('showPrev')
    setSearchTerm('')
    setselectedCustomer({})
    setSelectedProject({})
    setProjectSelectedBool(false)
    setCustomerSelectedBool(false)

    setIsLoadingMainTable(true)

    const token = getCurrentUser

    let today = new Date();
    let yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    let dd = yesterday.getDate();
    let mm = 0
    let yyyy = yesterday.getFullYear();

    for (let key in months) {
      if (yesterday.toDateString().split(' ')[1] === key) {
        mm = months[key]
      }
    }

    let yesterdayDate = yyyy + '-' + (mm) + '-' + (dd < 10 ? '0' + dd : dd)

    if (yesterdayDate) {
      let fetchPickupsByPrevDate = await fetchPickupsHistory(token, yesterdayDate)
      if (fetchPickupsByPrevDate.Reports) {
        setProjectsArr([...fetchPickupsByPrevDate.Reports])
        setprojectsArrLength(fetchPickupsByPrevDate.Reports.length)
        setIsLoadingMainTable(false)
      }
    }
  }

  const setAllHandle = () => {
    setPrevOrCurrentToggle('showAll')
    setIsLoadingMainTable(true)

    loadMoreItems()

    setSearchTerm('')
    setselectedCustomer({})
    setSelectedProject({})
    setProjectSelectedBool(false)
    setCustomerSelectedBool(false)

    setTimeout(function () {
      setIsLoadingMainTable(false)
    }, 500);

  }

  const searchBarClickedHandle = () => {
    // updated
    setselectedCustomer({})
    setSelectedProject({})
    setProjectSelectedBool(false) //
    setCustomerSelectedBool(false)
    setcustomerLatLng('')
    setIndexOfSelectedPickup('')
    setPickupImage('')

    setSearchBarClicked(!searchBarClicked)
  }

  const rowClickedHandle = async () => {
    setSelectedPickupBool(true)
  }

  const rowClickedFalseHandle = async () => {
    // setcustomerLatLng({})
    setSelectedPickupBool(false)
    setProjectSelectedBool(false)
  }

  const toggleBackToAllpickups = () => {
    setselectedCustomer({})
    setSelectedProject({})
    // setProjectSelectedBool(false)
    setCustomerSelectedBool(!customerSelectedBool)
  }

  const fetchSingleProject = async (id, index) => {
    // disneyland
    let latLng = {
      lat: 33.812511,
      lng: -117.918976
    }

    if (id.latitude && id.longitude) {
      latLng.lat = parseFloat(id.latitude)
      latLng.lng = parseFloat(id.longitude)

      setcustomerLatLng(latLng)
    } else {

      setcustomerLatLng(latLng)
    }

    setIndexOfSelectedPickup(index)
    setPickupImage(id.photo_link)
    setProjectSelectedBool(true)
  }

  const searchHandle = async event => {
    const token = getCurrentUser
    let searchValue = event.target.value

    // setProjectsArr([])
    // setprojectsArrLength(0)

    setSearchTerm(searchValue);

    if (searchValue.length > 2) {
      let searchPickupArr = await fetchSearchPickups(token, searchValue)

      setTimeout(() => {
        if (searchPickupArr.Reports) {
          setProjectsArr([...searchPickupArr.Reports])
        }
      }, 500);

    } else if (searchValue.length === 0) {

      setIsLoadingMainTable(true)

      const fetchPickupsCall = await fetchPickups(token)

      if (fetchPickupsCall) {
        setIsLoadingMainTable(false)

        if (fetchPickupsCall.Reports) {
          setProjectsArr([...fetchPickupsCall.Reports])
        }
      }
      setSearchTerm('');
    }

  };

  const downloadhandle = () => {
    let arr = []

    let datetime = new Date().toISOString()
    let year = datetime.substring(0, 10).split('-')[0]
    let month = datetime.substring(0, 10).split('-')[1]
    let day = datetime.substring(0, 10).split('-')[2]

    let todaysDate = `${month}/${day}/${year}`

    arr = []
    if (projectsArr.length > 0) {

      if (searchTerm.length > 0) {

        projectsArr.map((data, i) => {
          // https://stackabuse.com/compare-two-dates-in-javascript/
          let datetime = data.checkin_timestamp
          let year = datetime.substring(0, 10).split('-')[0]
          let month = datetime.substring(0, 10).split('-')[1]
          let day = datetime.substring(0, 10).split('-')[2]
          // let timeStamp = `${month}-${day}-${year}`

          if (prevOrCurrentToggle === 'showCurrent') {
            arr.push(data)
          }
          if (prevOrCurrentToggle === 'showPrev') {
            arr.push(data)
          }
          if (prevOrCurrentToggle === 'showAll') {
            arr.push(data)
          }

        })

      } else {

        for (let i = 0; i < projectsArr.length; i++) {
          let data = projectsArr[i]

          // https://stackabuse.com/compare-two-dates-in-javascript/
          let datetime = data.checkin_timestamp
          let year = datetime.substring(0, 10).split('-')[0]
          let month = datetime.substring(0, 10).split('-')[1]
          let day = datetime.substring(0, 10).split('-')[2]
          let timeStamp = `${month}/${day}/${year}`

          if (prevOrCurrentToggle === 'showCurrent') {
            arr.push(data)
          }
          if (prevOrCurrentToggle === 'showPrev') {
            arr.push(data)
          }
          if (prevOrCurrentToggle === 'showAll') {
            arr.push(data)
          }

        }

      }
      // 
      setDownLoadData(arr)
      // let dataBody = `Customer Name,Check in,Photo Link,Notes`
      let dataBody = `Customer Name,Street Name,City,State,Check in,Photo Link,Notes`

      if (arr.length > 0) {

        for (let i = 0; i < arr.length; i++) {
          let data = arr[i]
          // 
          let datetime = data.checkin_timestamp

          let currentTimeZone = new Date(datetime).toString()

          let monthIntoNum = ''
          let convertMilitaryTime = ''
          let currentTimeZoneShortened = ''

          if (datetime) {
            for (let key in months) {
              if (key === currentTimeZone.split(' ')[1]) {
                monthIntoNum = months[key]
              }
            }
            convertMilitaryTime = moment(currentTimeZone.split(' ')[4], 'HH:mm:ss').format('h:mm:ss A')
            currentTimeZoneShortened = `${monthIntoNum}-${currentTimeZone.split(' ')[2]}-${currentTimeZone.split(' ')[3]}-${convertMilitaryTime}`
          }
          // 

          let address = data.full_address ? data.full_address.split(',') : ''
          let fullName = `${data.firstname} ${data.lastname}`
          let notes = data.notes
          let streetAddress = address ? address[0] : ''
          let city = address ? address[1] : ''
          let stateAndZip = address ? address[2] : ''
          let country = address ? address[3] : ''
          let photo_link = data.photo_link
          let issue = data.issues_reported

          dataBody += `\n ${fullName},${streetAddress}, ${city}, ${stateAndZip},${currentTimeZoneShortened},${photo_link},${notes}`
        }
      }

      let blob = new Blob([dataBody], { type: "text/plain;charset=utf-8" });

      FileSaver.saveAs(blob, "Recyclops dyper pick up data.csv");

    } else {
      console.log('no data')
    }
    // 
  }

  return (
    <div className='content-wrapper' style={tableStyling.pagePadding}>

      <div className="row" style={tableStyling.pickUpContainer}>
        <div className={!projectSelectedBool ? 'col-12' : "col-8"}>
          {!customerSelectedBool
            ? <div className="" >
              <div>
                <div>
                  {!searchBarClicked
                    ? <div className="row">
                      <div className="col-6">
                        <div className="" style={tableStyling.pageTitleFont}>
                          {customerSelectedBool
                            ? <div onClick={() => toggleBackToAllpickups()} >
                              <div style={tableStyling.pageTitleSize}>
                                {`< Route Management `}
                              </div>
                            </div>
                            : <div style={tableStyling.pageTitleSize}>{'Route Management'}</div>
                          }
                        </div>
                      </div>
                      <div className="col-6 text-right" style={tableStyling.pageTitleFont}>
                        <div className="row" style={{ paddingTop: '1%' }}>
                          <div className="col"></div>
                          <div className="col-sm"></div>
                          <div className="col">
                            <div className="row" >
                              <div className="col-6"></div>
                              <div className="col-3 text-right">
                                <img
                                  style={tableStyling.searchIcon}
                                  id='searchButton' onClick={searchBarClickedHandle}
                                  src={search}
                                  alt="pickupImage" />
                              </div>
                              <div className="col-3 text-left">
                                <img
                                  style={tableStyling.downloadIcon}
                                  id='toDownload' onClick={() => downloadhandle()}
                                  src={download}
                                  alt="pickupImage" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    : <div style={{ paddingBottom: '.4%' }}>
                      <div className="row" >
                        <div className="col-6">
                          <div className="" style={tableStyling.pageTitleFont}>
                            <div style={tableStyling.pageTitleSize}>{'Route Management'}</div>
                          </div>
                        </div>
                        <div className="col-6 text-right" style={tableStyling.pageTitleFont}>
                          <div className="row" >
                            <div className="col-1">
                              <img
                                style={tableStyling.searchIcon}
                                id='searchButtonClicked' onClick={searchBarClickedHandle}
                                src={search}
                                alt="pickupImage" />
                            </div>
                            <div className="col-10">
                              <div className="col">
                                <span>
                                  <input
                                    style={tableStyling.searchInput}
                                    type='text' placeholder={' Search for customer, name, state, etc...'}
                                    value={searchTerm} onChange={(e) => searchHandle(e)} />
                                </span>
                              </div>
                            </div>
                            <div className="col-1">
                              <div className="row">
                                <div className="col text-left">
                                  <img
                                    style={tableStyling.downloadIcon}
                                    id='toDownload' onClick={() => downloadhandle()}
                                    src={download}
                                    alt="pickupImage" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>

                <div style={{ paddingTop: '.5%' }}>

                  <div style={tableStyling.cardStyling}>
                    {
                      <div>
                        <div className="row text-center">
                          <div className="col btn" onClick={setPrevHandle}
                            style={prevOrCurrentToggle === 'showPrev'
                              ? tableStyling.clickedHistoryTabPickup
                              : tableStyling.notClickedHistoryTabPickup
                            }>
                            <div style={tableStyling.headerTabStyling}>History</div>
                          </div>
                          <div className="col btn" onClick={setCurrentHandle}
                            style={prevOrCurrentToggle === 'showCurrent'
                              ? tableStyling.clickedCurrentTab
                              : tableStyling.notClickedCurrentTab
                            }>
                            <div style={tableStyling.headerTabStyling}>Today</div>
                          </div>

                          <div className="col btn" onClick={setAllHandle}
                            style={prevOrCurrentToggle === 'showAll'
                              ? tableStyling.clickedAllTabPickup
                              : tableStyling.notClickedAllTabPickup
                            }>
                            <div style={tableStyling.headerTabStyling}>All Data</div>
                          </div>
                        </div>
                      </div>
                    }

                    <div style={tableStyling.scrollBarStylingPickupTable}>
                      {isLoadingMainTable
                        ? <div className="col-12" style={tableStyling.isLoadingSpinner}>
                          <BallTriangle
                            heigth="100"
                            width="100"
                            color="#FCC816"
                            ariaLabel="loading-indicator"
                          />
                        </div>
                        : <PickupsTable
                          projectsArrLength={projectsArrLength}
                          isPaginating={isPaginating}
                          isFetching={isFetching}
                          lastElementRef={lastElementRef}
                          loadMoreItems={loadMoreItems}
                          selectedPickupBool={selectedPickupBool}
                          rowClickedHandle={rowClickedHandle}
                          indexOfSelectedPickup={indexOfSelectedPickup}
                          projectsArr={projectsArr}
                          searchTerm={searchTerm}
                          fetchSingleCustomer={fetchSingleCustomer}
                          fetchSingleProject={fetchSingleProject}
                          setPrevHandle={setPrevHandle}
                          prevOrCurrentToggle={prevOrCurrentToggle}
                          setCurrentHandle={setCurrentHandle}
                          setPrevHandle={setPrevHandle}
                          setAllHandle={setAllHandle}

                          searchBarClicked={searchBarClicked}
                          searchBarClickedHandle={searchBarClickedHandle}
                          searchHandle={searchHandle}
                          downloadhandle={downloadhandle} />
                      }
                    </div>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
            : ''
          }</div>

        {/*  */}

        {isLoadingCustomerSelectedTable
          ? <div className="col-12"
            style={tableStyling.isLoadingSpinner}>
            <BallTriangle
              heigth="100"
              width="100"
              color="#FCC816"
              ariaLabel="loading-indicator"
            />
          </div>
          : <div className="col">

            {projectSelectedBool
              ? <div className="col-12 text-right"
                style={{ paddingTop: "5%" }}
              >
                <div className='btn fas fa-times' style={tableStyling.closeButton} onClick={() => rowClickedFalseHandle()}></div>
              </div>
              : ''}

            {projectSelectedBool
              ? <div>
                <div style={tableStyling.panelCardTopPickup}>

                  <div className='col-12'>
                    <div className="row">
                      <div className="col-10" >
                        <div style={tableStyling.textImage} >Image</div>
                      </div>

                    </div>

                    <div style={{ padding: '1%' }}></div>

                    <div>
                      {pickupImage.length > 0
                        ?
                        <div className='container' style={tableStyling.centerEverything}>
                          <div className="panelContent" >
                            <div className="row panelRow">
                              <div className="panelCell">
                                <a href={pickupImage} style={{ display: "table-cell" }} target="_blank">
                                  <img
                                    style={tableStyling.pickUpImageSize}
                                    src={pickupImage}
                                  />
                                  <div className='text-center' style={tableStyling.ClickToEnlargeOrNoImageMsgOrMaps}>{'Click to enlarge'}</div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        : <div className='container' style={tableStyling.centerEverything}>
                          <div className="panelContent" >
                            <div className="row panelRow">
                              <div className="panelCell">
                                <img
                                  style={tableStyling.pickUpImageSize}
                                  src={dyperlogo}
                                />
                                <div className='text-center' style={tableStyling.ClickToEnlargeOrNoImageMsgOrMaps}>No Image</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    </div>

                  </div>
                </div>

                <div style={{ padding: '1%' }}></div>

                <div style={tableStyling.panelCardBottomPickup}>
                  <div className='col-12' >
                    <div className="row">
                      <div className="col-10" >
                        <div style={tableStyling.ClickToEnlargeOrNoImageMsgOrMaps} >Maps</div>

                      </div>
                    </div>

                    <div>
                      {customerLatLng.lat === 33.812511 && customerLatLng.lng === -117.918976
                        ?
                        <div className='container' style={tableStyling.centerEverything}>
                          <div className="panelContent" >
                            <div className="row panelRow">
                              <div className="panelCell">
                                <img
                                  style={tableStyling.pickUpImageSize}
                                  src={dyperlogo}
                                />
                                <div className='col-12 text-center' style={tableStyling.ClickToEnlargeOrNoImageMsgOrMaps}>{customerLatLng.lat === 33.812511 && customerLatLng.lng === -117.918976
                                  ? 'No Map'
                                  : <div>{``}</div>
                                }</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        :
                        <div className='container' style={tableStyling.centerEverything}>
                          <div className="panelContent" >
                            <div className="row panelRow">
                              <div className="panelCell">
                                <div className="container" >
                                  <MapPinDisplay
                                    customerLatLng={customerLatLng}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              : ''
            }
          </div>}
      </div>

    </div >
  );
}

const mapStateToProps = (state) => {
  return {
    selectedCustomer: state.selectedCustomer.customer,
    getCurrentUser: state.getCurrentUser.jwtToken
  };
};

const mapDispachToProps = (dispach) => {
  return {
    customerSelected: (state) => { dispach({ type: 'CUSTOMER_SELECTED', data: state }) },
  };
}

export default connect(mapStateToProps, mapDispachToProps)(PickupData)