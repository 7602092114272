/* eslint-disable */
import React, { Component } from "react";
import dashboardStyles from "./DashboardStyling";
import DashboardHeader from './dashboardHeader'
import TopStatusBoxes from './display/statusBoxes/topStatusBoxes'
import CompanyStatus from './display/template/companyStatus';
import BottomStatusBoxes from './display/statusBoxes/bottomStatusBoxes';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date().toLocaleString().split(',')[0],
      listOfstateArr: [],
      numberOfStates: 0
    };
  }

  async componentDidMount() { }

  render() {
    const { numberOfStates, date } = this.state

    return (
      <div style={dashboardStyles.dashboard_padding_Left}>
        <div
          className="content-wrapper"
          style={dashboardStyles.dashboard_background_color}
        >
          <section>
            <div>
              <DashboardHeader date={date} />

              <TopStatusBoxes numberOfStates={numberOfStates} />
              <br />

              <div className='conatiner'>
                <div className="row">

                  <section className="col-6">
                    {/* <div className="card container" style={dashboardStyles.graphTable} >
                      <div >
                        <br />
                        <GraphLayoutDashBoard />
                      </div>
                    </div> */}
                  </section>

                  <section className="col-3" >
                    {/* <div className="card" style={dashboardStyles.statusTable}>
                      <div className="card-header border-0" >
                        <CompanyStatus />
                      </div>
                      <div className="card-body"></div>
                    </div> */}
                  </section>

                  <section className="col-3">
                    {/* <div className="card" style={dashboardStyles.statusTable}>
                      <div className="card-header border-0" >
                        <CompanyStatus />
                      </div>
                      <div className="card-body"></div>
                    </div> */}
                  </section>

                </div>
              </div>
            </div>

            <div className='conatiner' style={dashboardStyles.BottomStatusBoxesContainer}>
              {/* <BottomStatusBoxes />  */}
            </div>

          </section>
        </div>
        <br />
      </div>
    );
  }
}


export default Dashboard
