/* eslint-disable */
import React, { Component } from "react";
import styles from "../../AuthStyling";
import { NavLink } from "react-router-dom";

import dashboardStyles from "../dashboard/DashboardStyling";
import menuStyles from "../menu/MenuStyles";

import dyperlogo from "../.././dyperlogo.png";

import ProfileButton from "../menu/profileButton";
import DepartmentsButton from "../menu/departmentsButton";

import { Auth } from "aws-amplify";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async signOut() {
    const user = await Auth.signOut();
  }

  render() {
    return (
      <div style={{ fontSize: '90%' }}>
        <aside
          className="main-sidebar elevation-4"
          style={dashboardStyles.dashboard_theme}
        >
          <br />
          <br />
          <div style={{ paddingLeft: '9%' }}>
            <img
              style={styles.recyclops_menu_logo}
              src={dyperlogo}
              alt="Logo"
            />
          </div>
          <br />
          {/* Sidebar */}
          <div className="sidebar">
            {/* <div style={menuStyles.dividerLine}></div> */}
            {/* Sidebar user panel (optional) */}
            <div style={dashboardStyles.dividerLine}></div>

            {/* <br /> */}
            {/* <DepartmentsButton /> */}

            <br />
            <br />

            <nav className="mt-2">
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
              >

                {/* <li className="nav-item">
                  <NavLink
                    href="#"
                    className="nav-link menu_item_selected_highlight"
                    to="/"
                    exact
                    style={menuStyles.menu_item_layout_style}
                  >
                    <i className="nav-icon fas fa-home" />
                    <p>pickupdata</p>
                  </NavLink>
                </li> */}

                <li className="nav-item">
                  <NavLink
                    href="#"
                    to="/"
                    exact
                    className="nav-link menu_item_selected_highlight"
                    style={menuStyles.menu_item_layout_style}
                  >
                    <div style={menuStyles.menu_item_sizing}>
                      <div className="nav-icon fas fa-truck-moving"></div>
                      {` Route Management`}
                    </div>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    href="#"
                    to="/customerdata"
                    exact
                    className="nav-link menu_item_selected_highlight"
                    style={menuStyles.menu_item_layout_style}
                  >
                    <div style={menuStyles.menu_item_sizing}>
                      <div className="nav-icon fas fa-baby"></div>
                      {`Customer Management`}
                    </div>
                  </NavLink>
                </li>

                {/* <li className="nav-item">
                  <NavLink
                    href="#"
                    to="/issuesdata"
                    exact
                    className="nav-link menu_item_selected_highlight"
                    style={menuStyles.menu_item_layout_style}
                  >
                    <i className="nav-icon fas fa-circle" />
                    <p>Issues Data</p>
                  </NavLink>
                </li> */}

                {/* <li className="nav-item">
                  <a
                    href="#"
                    className="nav-link"
                    style={menuStyles.menu_item_layout_style}
                  >
                    <i className="nav-icon fas fa-circle" />
                    <p>Blank</p>
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    href="#"
                    className="nav-link"
                    style={menuStyles.menu_item_layout_style}
                  >
                    <i className="nav-icon fas fa-circle" />
                    <p>Blank</p>
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    href="#"
                    className="nav-link"
                    style={menuStyles.menu_item_layout_style}
                  >
                    <i className="nav-icon fas fa-circle" />
                    <p>Blank</p>
                  </a>
                </li> */}
              </ul>
            </nav>
            {/* Second half of menu */}

            <div
              style={menuStyles.bottomMenuPosition}
            >

              {/* <ProfileButton /> */}

              <nav className="mt-2">
                <ul
                  className="nav nav-pills nav-sidebar flex-column"
                  data-widget="treeview"
                  role="menu"
                  data-accordion="false"
                >
                  {/* <li className="nav-item">
                    <NavLink
                      href="#"
                      to="/settings"
                      exact
                      className="nav-link menu_item_selected_highlight"
                      style={menuStyles.menu_item_layout_style}
                    >
                      <i className="nav-icon fas fa-cog" />
                      <p>Settings</p>
                    </NavLink>
                  </li> */}
                  <li className="nav-item">

                    <NavLink
                      href="#"
                      to="/"
                      id='logoutButton'
                      exact
                      className="nav-link"
                      style={menuStyles.menu_item_logout_layout_style}
                    >
                      {/* <i className="nav-icon fas fa-sign-out-alt" />
                      <p style={menuStyles.menu_item_sizing}>Logout</p> */}
                      <div type="button" data-toggle="modal" data-target="#exampleModalCenter">
                        <i className="nav-icon fas fa-sign-out-alt" />
                        <p style={menuStyles.menu_item_sizing}>Logout</p>
                      </div>
                    </NavLink>
                  </li>

                </ul>
              </nav>

              <br />
              <br />
              <br />

            </div>
          </div>
        </aside>


        <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-center">
                <h1 style={{
                  paddingTop: '2%',
                  paddingLeft: '1%',
                  fontWeight: 'bold'
                }}>Would you like to sign out?</h1>
              </div>
              <div className="modal-footer">
                <button type="button" style={styles.button_yellow_border} data-dismiss="modal">Close</button>
                <button style={styles.buttonYellow} type="button" onClick={() => this.signOut()}>Sign out</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Menu
